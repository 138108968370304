import AllCategoriesIMG from '../assets/category.svg';
import ElectronicIMG from '../assets/electronic.svg';
import ModeBeautyIMG from '../assets/mode-beauty.svg';
import AutomobileIMG from '../assets/automobile.svg';
import MotoIMG from '../assets/motorcycle.svg';
import ImmovableIMG from '../assets/immovable.svg';
import ServiceIMG from '../assets/service.svg';
import HomeApplianceIMG from '../assets/homeappliance.svg';
import OfficeIMG from '../assets/office.svg';
import ConsoleIMG from '../assets/console.svg';
import HouseIMG from '../assets/house.svg';
import ToyIMG from '../assets/toys.svg';
import BookIMG from '../assets/book.svg';
import JobIMG from '../assets/job.svg';
import OthersIMG from '../assets/more.svg';
import RentalIMG from '../assets/rental.svg';

import jwt_decode from 'jwt-decode';

export const categories = [
    {
        id: '0',
        name: 'all_categories',
        logo: AllCategoriesIMG,
    },
    {
        id: '1',
        name: 'electronic',
        logo: ElectronicIMG,
    },
    {
        id: '2',
        name: 'mode_beauty',
        logo: ModeBeautyIMG,
    },
    {
        id: '3',
        name: 'automobile',
        logo: AutomobileIMG,
    },
    {
        id: '4',
        name: 'moto',
        logo: MotoIMG,
    },
    {
        id: '5',
        name: 'immovable',
        logo: ImmovableIMG,
    },
    {
        id: '6',
        name: 'service',
        logo: ServiceIMG,
    },
    {
        id: '7',
        name: 'home_appliance',
        logo: HomeApplianceIMG,
    },
    {
        id: '8',
        name: 'office',
        logo: OfficeIMG,
    },
    {
        id: '9',
        name: 'console',
        logo: ConsoleIMG,
    },
    {
        id: '10',
        name: 'house',
        logo: HouseIMG,
    },
    {
        id: '11',
        name: 'toys',
        logo: ToyIMG,
    },
    {
        id: '12',
        name: 'books',
        logo: BookIMG,
    },
    {
        id: '13',
        name: 'jobs',
        logo: JobIMG,
    },
    {
        id: '14',
        name: 'rental',
        logo: RentalIMG,
    },
    {
        id: '15',
        name: 'others',
        logo: OthersIMG,
    },
];

export const product_status = [
    {
        id: '0',
        name: 'used_product'
    },
    {
        id: '1',
        name: 'new_product'
    },
    {
        id: '3',
        name: 'service'
    }
];

export const cities = [
	{
        id: '0',
		latitude: 18.08581,
		longitude: -15.9785,
		name: 'nouakchott',
    },
    {
        id: '1',
        latitude: 18.88333,
        longitude: -7.0,
        name: 'hodhC',
    },
    {
        id: '2',
        latitude: 16.0,
        longitude: -12.83333,
        name: 'gorgol',
    },
    {
        id: '3',
        latitude: 16.58333,
        longitude: -11.58333,
        name: 'assaba',
    },
    {
        id: '4',
        latitude: 17.2475638,
        longitude: -13.4037714,
        name: 'brakna',
    },
    {
        id: '5',
        latitude: 16.58333,
        longitude: -9.83333,
        name: 'hodhG',
    },
    {
        id: '6',
        latitude: 17.922642,
        longitude: -14.8476017,
        name: 'trarza',
    },
    {
        id: '7',
        latitude: 15.3766896,
        longitude: -12.124663,
        name: 'guidimaka',
    },
    {
        id: '8',
        latitude: 20.8019537,
        longitude: -16.0012708,
        name: 'nouadhibou',
    },
    {
        id: '9',
        latitude: 18.5729893,
        longitude: -10.3937926,
        name: 'tagant',
    },
    {
        id: '10',
        latitude: 21.0090623,
        longitude: -10.2207449,
        name: 'adrar',
    },
    {
        id: '11',
        latitude: 24.1951392,
        longitude: -9.6664199,
        name: 'tirisZemmour',
    },
    {
        id: '12',
        latitude: 20.06963539123535,
        longitude: -15.062479019165039,
        name: 'inchiri',
    },
];

export const countries = [
    {
        id: '0',
        name: 'mauritania',
    }
];

export const getDecodedToken = (user) => {
    const decoded = jwt_decode(user.token);
    const userdata = decoded.user;
    const data =  {
        user,
        id: userdata.id,
        username: userdata.username,
        email: userdata.email,
        role: userdata.role,
        token: user.token,
        refresh_token: user.refresh_token,
        create_time: userdata.create_time,
    }
    return data;
}

export const validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase());
}