import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Pagination } from 'react-bootstrap';
import {favored} from '../../API/publication';
import {getDecodedToken} from '../../Utils/functions';
import i18n from '../../Utils/i18n';
import { useDispatch, useSelector } from 'react-redux';
import PublicationCard from './PublicationCard';

const Favored = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData.userData);
    const [publications, setPublications] = useState(null);
    const [pages, setPages] = useState(0);
    const [active, setActive] = useState(1);
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);

    useEffect(() => {
        if(userData.id) {
            loadData(0);
        }
    }, [userData]);

    const loadData = (pageNo) => {
        window.scrollTo(0, 0);
        const {id, token, refresh_token} = userData;
        favored(id, pageNo, token, refresh_token)
        .then(res =>  {
            if(res.Error) {
                setPublications([]);
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('favoris'), message: i18n.t('error'), type: 'error'}});
            } else {
                if(res.Message === 'Token refreshed') {
                    const payload = getDecodedToken(res);
                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                    setIsTokenRefreshed(true);
                } else {
                    setPublications(res.publication);
                    setPages(res.pages);
                }
            }
        })
        .catch(err => {
            setPublications([]);
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('favoris'), message: i18n.t('error'), type: 'error'}});
        });
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            loadData();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const pagination = () => {
        let items = [];
        for(let i = 1; i <= pages; i ++) {
            items.push(
                <Pagination.Item onClick={() => {setActive(i); loadData(i-1)}} key={i} active={i === active}>
                    {i}
                </Pagination.Item>
            );
        }
        return (
            <Pagination size='sm' dir='ltr' onClick={() => pagination()}>
                {items}
            </Pagination>
        );
    }

    return (
        <Container className='Body-container'>
            <h3 className='title'>
                {i18n.t('favored_publications')}
            </h3>
            {!userData.id ? 
                <div className='not_connected_card'>
                    <div className='_card'>
                        <img src={require('../../assets/logo.png')} alt='app-logo' className='img' />
                        <p className='label'>{i18n.t('youre_not_connected')}</p>
                        <Button
                            variant='flat'
                            onClick={props.openModal.bind(this, 'signin')} >
                            <small>{i18n.t('signin')}</small>
                        </Button>
                    </div>
                </div>
            :   
                publications && publications.length > 0 ?
                <Row className='publication_row'>
                    {publications && publications.map((publication, index) =>
                        <PublicationCard publication={publication} key={index} />
                    )}
                </Row>
            :
                <div className='not_connected_card'>
                    <div className='_card'>
                        <img src={require('../../assets/logo.png')} alt='app-logo' className='img' />
                        <p className='label'>{i18n.t('no_favored')}</p>
                    </div>
                </div>
            }
            {pages > 1 ? pagination() : null}
        </Container>
    );
}
export default Favored;
