const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export function allCategories(page) {
    const url = `${REACT_APP_API_URL}publication/category/all?page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function allPublicationsByCategory(category_name, page) {
    const url = `${REACT_APP_API_URL}publication/category/allByCategory?category_name=${category_name}&page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function publicationById(user_id, connected_user_id, publication_id) {
    const url = `${REACT_APP_API_URL}publication/category/findById?user_id=${user_id}&publication_id=${publication_id}&connected_user_id=${connected_user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function suggestions(category_name, publication_id) {
    const url = `${REACT_APP_API_URL}publication/category/suggestion?category_name=${category_name}&publication_id=${publication_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function allPublicationsByUser(user_id, page) {
    const url = `${REACT_APP_API_URL}publication/category/findByUser?user_id=${user_id}&page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function addNewView(publication_id) {
    const url = `${REACT_APP_API_URL}publication/newView?publication_id=${publication_id}`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function likeDislike(user_id, publication_id, like_dislike, token, refresh_token) {
    const url = `${REACT_APP_API_URL}publication/fav?user_id=${user_id}&publication_id=${publication_id}&like_dislike=${like_dislike}`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function favored(user_id, page, token, refresh_token) {
    const url = `${REACT_APP_API_URL}publication/favored?user_id=${user_id}&page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function upload(data, urls, publication_creation_date, token, refresh_token) {
    const url = `${REACT_APP_API_URL}publication/upload`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
        body: JSON.stringify({
            product_service_name: data.productServiceName,
            details: data.details,
            price: data.price,
            publication_status: data.publication_status,
            category_name: data.category,
            address: data.address,
            city: data.city,
            country: data.country,
            product_status: data.productStatus,
            user_id: data.user_id,
            publication_creation_date,

            urls,
        }),
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function changeStatus(status, user_id, publication_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}publication/changeStatus`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
        body: JSON.stringify({
            user_id,
            publication_id,
            status,
        }),
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function searchByProductName(search, page) {
    const url = `${REACT_APP_API_URL}publication/search?search=${search}&page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function reportPublication(name, email, reason, message, publication_id) {
    const url = `${REACT_APP_API_URL}publication/report`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name, email, reason, message, publication_id
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function markAsSoldClosed(user_id, publication_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}publication/soldClosed`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
        body: JSON.stringify({
            user_id, publication_id,
        }),
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}
