import React, { useEffect, useState } from 'react';
import {Pagination, Container, Row } from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {allCategories, allPublicationsByCategory} from '../../API/publication';
import i18n from '../../Utils/i18n';
import PublicationCard from './PublicationCard';
import { useDispatch } from 'react-redux';
import DisplayLoading from '../Partials/DisplayLoading';

const Publications = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [publications, setPublications] = useState(null);
    const [pages, setPages] = useState(0);
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadPublications(active);
    }, []);

    const loadPublications = (pageNo) => {
        const data = location.state ? location.state : {id: '0', title: 'Toutes les categories'};
        if(data && data.id === '0') {
            allCategories(pageNo -1)
            .then(res =>  {
                if(res.Error) {
                    setPublications([]);
                    dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
                } else if(res.publication.length < 0) {
                    setPublications([]);
                } else {
                    setPublications(res.publication);
                    setPages(res.page);
                }
            })
            .catch(err => {
                setPublications([]);
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            });
        } else {
            allPublicationsByCategory(data.id, pageNo -1)
            .then(res =>  {
                if(res.Error) {
                    setPublications([]);
                    dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
                } else if(res.publication.length < 0) {
                    setPublications([]);
                } else {
                    setPublications(res.publication);
                    setPages(res.page);
                }
            })
            .catch(err => {
                setPublications([]);
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            });
        }
    }

    const pagination = () => {
        window.scrollTo(0, 0);
        let items = [];
        for(let i = 1; i <= pages; i ++) {
            items.push(
                <Pagination.Item onClick={() => {setActive(i); loadPublications(i)}} key={i} active={i === active}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination size='sm' dir='ltr' onClick={pagination}>
                {items}
            </Pagination>
        );
    }

    return (
        <Container className='Body-container publications'>
            <h1 className='title'>
                {location.state ? i18n.t(location.state.title) : 'Toutes les categories'}
            </h1>

            <Row className='publication_row'>
                {publications && publications.length > 0 ? publications.map((publication, index) =>
                    <PublicationCard publication={publication} key={index} />
                
                )
                : 
                    publications && publications.length === 0 ? <p>O annonces correspondantes</p>
                : 
                    <DisplayLoading />
            }
            </Row>
            {pages > 1 ? pagination() : null}
        </Container>
    );
}

export default Publications;
