import React from 'react';
import {likeDislike} from '../../../API/publication';
import i18n from '../../../Utils/i18n';
import PinIcon from '../../../assets/pin.svg';
import ViewIcon from '../../../assets/view.svg';
import CalendarIcon from '../../../assets/calendar.svg';
import PriceIcon from '../../../assets/price.svg';
import ProductIcon from '../../../assets/product.svg';
import HeartIcon from '../../../assets/heart.svg';
import EmptyHeartIcon from '../../../assets/empty-heart.svg';
import {cities, countries} from '../../../Utils/functions';
import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr';
import ar from 'javascript-time-ago/locale/ar';
import en from 'javascript-time-ago/locale/en';
import { useDispatch } from 'react-redux';

const DetailsContainer = (props) => {
    const dispatch = useDispatch();
    const {publication, data, fav, alreadyViewed, _data, language} = props;

    let timeAgo;
    if(language) {
        if(language === 'ar') {
            TimeAgo.addLocale(ar);
            timeAgo = new TimeAgo('ar');
        } else if(language === 'fr'){
            TimeAgo.addLocale(fr);
            timeAgo = new TimeAgo('fr');
        } else if(language === 'en'){
            TimeAgo.addLocale(en);
            timeAgo = new TimeAgo('en');
        }
    } else {
        TimeAgo.addLocale(fr);
        timeAgo = new TimeAgo('fr');
    }
    
    const _likeDislike = () => {
        if(data.connectedUserId) {
            if(_data.fav) {
                props.setLikesChange(false, _data.likes-1);
                likeDislike(data.connectedUserId, publication.id, 'dislike', data.token, data.refresh_token);
            } else {
                props.setLikesChange(true, _data.likes+1);
                likeDislike(data.connectedUserId, publication.id, 'like', data.token, data.refresh_token);
            }
        } else {
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('youre_not_connected'), type: 'info'}});
        }
    }

    return (
        <div className='details'>
            <p className='detail_line'>
                <img src={CalendarIcon} alt='product logo' />
                {timeAgo.format(parseInt(publication.publication_creation_date))}
            </p>
            {publication.publication_status === '2' ?
                publication.category_name !== '6' && publication.category_name !== '13' && publication.category_name !== '14' ?
                    <p className='detail_line'>
                        <img src={ProductIcon} alt='product logo' />
                        {i18n.t('sold')}
                    </p>
                :
                    <p className='detail_line'>
                        <img src={ProductIcon} alt='product logo' />
                        {i18n.t('rented_closed')}
                    </p>
            : null
            }

            <p className='detail_line'>
                <img src={PriceIcon} alt='location logo' />
                {publication.price} MRU
            </p>

            <hr/>

            <p className='detail_detail'>
                {publication.details}
            </p>

            <hr/>

            {publication.address ? 
                <p className='detail_line'>
                    <img src={PinIcon} alt='location logo' />
                    {publication.address}
                </p>
            : null}

            <p className='detail_line'>
                <img src={PinIcon} alt='location logo' />
                {cities.flatMap((city) => { if(city.id === publication.city) { return i18n.t(city.name)} return null; } )},&nbsp;
                {countries.flatMap((country) => { if(country.id === publication.country) { return i18n.t(country.name)} return null; } )}
            </p>
                
            <hr/>
            
            {publication.product_status === '0' ?
                <p className='detail_line'>
                    <img src={ProductIcon} alt='product logo' />
                    {i18n.t('used_product')}
                </p> 
            :   publication.product_status === '1' ? 
                <p className='detail_line'>
                    <img src={ProductIcon} alt='product logo' />
                    {i18n.t('new_product')}
                </p>
            :   publication.product_status === '2' ?
                <p className='detail_line'>
                    <img src={ProductIcon} alt='product logo' />
                    {i18n.t('service_job_rental')}
                </p>
            : null}

            <p className='detail_line'>
                <img src={ViewIcon} alt='views logo' />
                {alreadyViewed ? publication.views : publication.views+1}&nbsp;
                {!_data.fav ? 
                    <>
                        <img src={EmptyHeartIcon} alt='views logo' onClick={_likeDislike} />
                    </> 
                : 
                    <>
                        <img src={HeartIcon} alt='views logo' onClick={_likeDislike} />
                    </>
                }
                {_data.likes}
            </p>
            <hr />
        </div>
    );
}

export default DetailsContainer;
