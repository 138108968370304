import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {facebookAuth} from '../../API/authentication';
import { userProfil } from '../../API/user';
import { getDecodedToken } from '../../Utils/functions';
import i18n from '../../Utils/i18n';
import DisplayLoading from '../Partials/DisplayLoading';

const FacebookAuthentication = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const code = location?.search.substring(6);

    useEffect(() => {
        facebookAuth(code)
        .then(res => {
            if(res.Error) {
                pushResponse('error', 'error')
            } else {
                if(res.Message === 'account already exists on aryilo') {
                    pushResponse('account_already_exists_on_aryilo', 'warning')
                } else if(res.Message === 'wrong user id') {
                    pushResponse('incorrect_email_password', 'warning')
                } else {
                    onSuccess('youre_connected', 'success', res)
                }
            }
        })
        .catch(err => pushResponse('error', 'error'));
    }, []);

    const pushResponse = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('connexion'), message: i18n.t(message), type}});
        return history.push('/');
    }

    const onSuccess = (message, type, res) => {
        const data = getDecodedToken(res);
        dispatch({type: 'UPDATE_USER_DATA', data});
        setUserProfil(data.id);
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('connexion'), message: i18n.t(message), type}});
        return history.push('/');
    }

    const setUserProfil = (userId) => {
        userProfil(userId)
        .then(res =>  {
            if(res.Error) {
                pushResponse('error', 'error')
            } else {
                dispatch({type: 'UPDATE_USER_PROFIL', data: res.profil[0]});
            }
        })
        .catch(err => pushResponse('error', 'error'));
    }

    return (
        <Container className='Body-container authenticate'>
            <div className='loading'>
                <DisplayLoading />
            </div>
        </Container>
    );
}

export default FacebookAuthentication;
