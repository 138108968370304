import React, { useState, useEffect } from 'react';
import { Container, Pagination, Row } from 'react-bootstrap';
import {allPublicationsByUser} from '../../API/publication';
import {userProfil} from '../../API/user';
import i18n from '../../Utils/i18n';

import ProfilPanel from './ProfilPanel';
import { useDispatch, useSelector } from 'react-redux';
import PublicationCard from '../Publication/PublicationCard';
import { useLocation } from 'react-router';

const Profil = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData.userData);
    const [publications, setPublications] = useState(null);
    const [active, setActive] = useState(1);

    const [publicationRows, setpublicationRows] = useState(0);
    const [pages, setPages] = useState(1);
    const [pageNo, setPageNo] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const user_id = location?.state ? location.state.id :
        location?.search ? parseInt(location.search.substring(4)) :
        userData?.id;
    const connectedUserId = userData.id;

    const {first_name, last_name, img_url, telephone, telephone_status} = data || '';
    const {username, email, create_time} = data  || userData || '';
    const {token, refresh_token} = userData || '';

    const _data = {user_id, username, telephone, telephone_status, connectedUserId, img_url, token, refresh_token};

    useEffect(() => {
        window.scrollTo(0, 0);
        if(user_id) {
            loadData();
            loadPublications(0);
        }
    }, []);

    const loadData = () => {
        userProfil(user_id)
        .then(res =>  {
            if(res.Error) {
                showAlert('error', 'error');
            } else {
                setData({...res?.profil[0], ...res?.user[0], ...res?.rating[0]});
            }
        })
        .catch(err => showAlert('error', 'error'));
    }

    const loadPublications = (pageNo) => {
        allPublicationsByUser(user_id, pageNo)
        .then(res =>  {
            if(res.Error) {
                setPublications([]);
                showAlert('error', 'error');
            } else {
                setPublications(res.publication);
                setpublicationRows(res.rows[0].numRows);
                setPages(res.page);
            }
        })
        .catch(err => {
            setPublications([]);
            showAlert('error', 'error');
        });
    }

    const pagination = () => {
        let items = [];
        for(let i = 1; i <= pages; i ++) {
            items.push(
                <Pagination.Item onClick={() => {setActive(i); loadPublications(i -1)}} key={i} active={i === active}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination size='sm' dir='ltr' onClick={() => pagination()}>
                {items}
            </Pagination>
        );
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('profil'), message: i18n.t(message), type}});
    }

    const triggerPropsChange = () => loadData();

    return (
        <Container className='Body-container'>
            <ProfilPanel props={props} data={_data} triggerPropsChange={triggerPropsChange} />

            <h3 style={{marginTop: '1em', textAlign: 'center', backgroundColor: '#1d2636', borderRadius: 10, padding: 5}}>
                {i18n.t('published_publications')} ({publicationRows})
            </h3>
            <Row className='publication_row'>
                {publications && publications.map((publication, index) =>
                    <PublicationCard props={props} publication={publication} key={index} />
                )}
            </Row>
            {pages > 1 ? pagination() : null}
        </Container>
    );
}

export default Profil;
