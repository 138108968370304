import {createStore, combineReducers} from 'redux';
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './Reducers/authReducer';
import publicationReducer from './Reducers/publicationReducer';
import socketReducer from './Reducers/socketReducer';
import messageReducer from './Reducers/messageReducer';
import conversationReducer from './Reducers/conversationReducer';
import alertMessage from './Reducers/pushAlert';
import notificationToken from './Reducers/notificationReducer';
import languageReducer from './Reducers/languageReducer';
import {stringify, parse} from 'flatted';

const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState),
);

const persistConfig = {
    key: 'root',
    storage,
    transforms: [transformCircular],
};

const appReducer = combineReducers({
    userData: authReducer,
    pViews: publicationReducer,
    socket: socketReducer,
    messages: messageReducer,
    conversations: conversationReducer,
    pushAlert: alertMessage,
    notification: notificationToken,
    language: languageReducer
});

const rootReducer = (state, action) => {
    if(action.type === 'USER_LOGOUT') {
        state = {};
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
