import React, { useState } from 'react';
import { ChatList } from 'react-chat-elements';
import { FormControl } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import ProfilIcon from '../../assets/user.svg';

const profilImage = process.env.REACT_APP_API_PROFIL_IMG_URL;

const UserView = (props) => {
    const [search, setSearch] = useState('');
    const {latest_conversations, chatDisplay, language} = props;

    const _latest_conversations = latest_conversations.map(({avatar, ...rest}) => ({
        ...rest,
        avatar: avatar ? `${profilImage}${avatar}` : ProfilIcon
    })).sort((a, b) => b.date - a.date);

    // const userSearch = (search) => {
    //     let users = this.state.users;
    //     if(search.length > 0) {
    //         return users.filter(u => u.user.name.toLowerCase().includes(search.toLowerCase()));
    //     } else {
    //         return users;
    //     }
    // }

    return (
        <div className='users_list_container' style={{display: chatDisplay}}>
            <FormControl
                style={ language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                type='text'
                placeholder={i18n.t('search')}
                className='mr-sm-2'
                id='search'
                value={search}
                className='search_input'
                disabled
                //onChange={this.handleChange}
            />

            <ChatList
                className='chat-list'
                onClick={props.onChatClicked}
                dataSource={_latest_conversations}
            />
        </div>
    );
}

export default UserView;