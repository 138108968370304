import React, { useState } from 'react';
import './App.scss';
import Navigation from './Navigation/Navigation';
import { BrowserRouter as Router} from 'react-router-dom';
import i18n from './Utils/i18n';
import NotificationContainer from './Components/Partials/NotificationContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import io from 'socket.io-client';
import { getLatestConversations, getAllConversations } from './API/message';
import {getDecodedToken} from './Utils/functions';

const url = process.env.REACT_APP_API__MAIN_URL;

const App = () => {
	const dispatch = useDispatch();
	const userData = useSelector(state => state.userData.userData);
	const alertData = useSelector(state => state.pushAlert);
	const conversations = useSelector(state => state.conversations);
	const internationalisation = useSelector(state => state.language);

    const [new_messages, setNewMessages] = useState(0);
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
	const [location, setLocation] = useState('');
    const [socket, setSocket] = useState();
	const [isSocketOn, setSocketOn] = useState(false);

	const {language, dir} = internationalisation;

	const user = {id: userData?.id, name: userData?.username};
	const token = userData?.token;
	const refresh_token = userData?.refresh_token;

	useEffect(() => {
        setLanguage();
	}, [language]);

	useEffect(() => {
        if(!isSocketOn && user.id) {
            if(socket) onReconnect();
            else initSocketConnection();

            setSocketOn(true);
        }

        if(!userData.id && socket && socket.connected) onDisconnect();

        if(userData.id) {
			if(conversations && conversations.conversations.length === 0)
				loadLatestConversations();
		}
	}, [isSocketOn, userData]);

	useEffect(() => {
        if(isTokenRefreshed) {
            if(location === 'loadLatestConversations') {
                loadLatestConversations();
            } else if(location === 'loadConversations') {
                loadConversations();
            }
            setLocation('');
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const loadLatestConversations = () => {
        getLatestConversations(user.id, token, refresh_token)
        .then(res =>  {
            if(res.Error) {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'info'}});
            } else if(res.Message === 'Token refreshed') {
                const payload = getDecodedToken(res);
                const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                setLocation('loadLatestConversations');
                setIsTokenRefreshed(true);
            } else {
                const dispatchreturn =  dispatch({type: 'UPDATE_LATEST_CONVERSATIONS', data: res.conversations});
                loadConversations();
            }
        })
        .catch(err => dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'info'}}) );
    };

    const loadConversations = () => {
        getAllConversations(user.id, token, refresh_token)
        .then(res =>  {
            if(res.Error) {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'info'}});
            } else if(res.Message === 'Token refreshed') {
                const payload = getDecodedToken(res);
                const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                setLocation('loadConversations');
                setIsTokenRefreshed(true);
            } else {
                dispatch({type: 'UPDATE_CONVERSATIONS', data: res.messages});
            }
        })
        .catch(err => dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'info'}}) );
    };

    const initSocketConnection = () => {
        if(user) {
            const _socket = io.connect(url, {transports: ['polling']});
            setSocket(_socket);
            dispatch({type: 'UPDATE_SOCKET', data: _socket});
        }
    };

    useEffect(() => {
        if (socket && user) setupSocketListeners(user);
    }, [socket]);

    const setupSocketListeners = (user) => {
        if(socket.listeners().length === 0) {
            socket.on('message', onMessageReceived.bind(this));
            socket.on('reconnect', onReconnect.bind(this));
            socket.on('disconnect', onDisconnect.bind(this));
            socket.on('unread-messages', onUnreadMessagesChecked.bind(this));
            socket.emit('sign-in', user);

            // error handler
            socket.on('connect_error', err => {return});
            socket.on('connect_failed', err => {return});
            socket.on('reconnect_error', (err) => {return});
            socket.on('reconnect_failed', () => {return});
        }
    };

    const onReconnect = () => {
        if(socket && socket.disconnected) {
            socket.connect() && socket.emit('sign-in', user);
        } else {
            !socket && socket.emit('sign-in', user);
        }
    };

    const onDisconnect = (e) => {
        socket && socket.disconnect();
        dispatch({type: 'REMOVE_SOCKET'});
        setSocket();
        setTimeout(() => {
            setSocketOn(false);
        }, 1000);
    };

    const onMessageReceived = (message) => {
        if(message.to === user.id) {
            message.unread = 1;
            setNewMessages(m => m+1);
			dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: message.title, message: message.subtitle, type: 'success'}});
            dispatch({type: 'ADD_NEW_MESSAGE', data: [message]});
        }
    };

    const onUnreadMessagesChecked = (e) => {
        if(e.unreadMessages > 0) {
            setNewMessages(e.unreadMessages);
			conversations &&
			conversations.conversations.length > 0 &&
			dispatch({type: 'UPDATE_CONVERSATIONS_ON_UNREAD_MESSAGE_CHECK', data: {last_conversations: e.last_conversations, conversations: e.conversations}});
        } else {
            setNewMessages(0);
            conversations &&
            conversations.latest_conversations.length > 0 &&
            dispatch({type: 'MARK_CONVERSATIONS_AS_READED'});
        }
    };

    useEffect(() => {
        let _new_messages = 0;
        conversations.latest_conversations.map(c => _new_messages += c.unread);
        if(_new_messages !== new_messages) setNewMessages(_new_messages);
	}, [conversations.latest_conversations]);

	const hideNotificationContainer = () => {
		dispatch({type: 'MARK_ALERT_AS_SEEN'});
	}

	const setLanguage = () => {
        if(language === 'ar') {
            setTimeout(() => {
                if(dir !== 'rtl')  window.location.reload();
            }, 1);
        } else {
            setTimeout(() => {
                if(dir !== 'ltr')  window.location.reload();
            }, 1);
        }
        i18n.locale = language;
    }

	return (
		<Router>
			<Navigation newMessages={new_messages} language={language} dir={dir} />
			<NotificationContainer alertData={alertData} hideNotificationContainer={hideNotificationContainer} />
		</Router>
	);
}

export default App;
