const initialState = {socket: {}};

function socket(state = initialState, action ) {
    let nextState = {};
    switch (action.type) {
        case 'UPDATE_SOCKET':
            nextState = { socket: action.data};
            return nextState;
        case 'REMOVE_SOCKET':
            state = initialState;
            return state;
        default:
            return state;
    }
}

export default socket;
