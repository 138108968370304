const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export function getChat(user_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}message/chat?user_id=${user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function getAllMessages(from, to, page, token, refresh_token) {
    const url = `${REACT_APP_API_URL}message/findAll?from=${from}&to=${to}&user_id=${from}&page=${page}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function markAsRead(user_id, source_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}message/read?user_id=${user_id}`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
        body: JSON.stringify({
            user_id,
            source_id,
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function getLatestConversations(user_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}message/latest?user_id=${user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function getAllConversations(user_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}message/all?user_id=${user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token,
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}
