import React, {useEffect, useState} from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {contactTeam} from '../../API/user';
import {useDispatch} from 'react-redux';
import {validateEmail} from '../../Utils/functions';
import DisplayLoading from '../Partials/DisplayLoading';

const Contact = (props) => {
    const dispatch =  useDispatch();
    const  [name, setName] = useState('');
    const  [email, setEmail] = useState('');
    const  [telephone, setTelephone] = useState('');
    const  [message, setMessage] = useState('');
    const  [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const _contactTeam = () => {
        if(name && name.length > 1) {
            if(email && validateEmail(email)) {
                if(message && message.length > 5) {
                    setIsLoading(true);
                    const data = {name, email, telephone, message};
                    contactTeam(data)
                        .then(res => {
                            if(res.Error) {
                                setIsLoading(false);
                                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: i18n.t('error'), type: 'error'}});
                            } else {
                                onSuccess();
                            }
                        })
                        .catch(err => {setIsLoading(false); dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: i18n.t('error'), type: 'error'}})})
                } else {
                    dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: `${i18n.t('fill_input')} ${i18n.t('message')}`, type: 'error'}});
                }
            } else {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: `${i18n.t('fill_input')} ${i18n.t('email')}`, type: 'error'}});
            }
        } else {
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: `${i18n.t('fill_input')} ${i18n.t('name')}`, type: 'error'}});
        }
    }

    const onSuccess = () => {
        setName('');
        setEmail('');
        setTelephone('');
        setMessage('');
        setIsLoading(false);
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('contact_us'), message: i18n.t('contact_team_successfull'), type: 'success'}});
    }

    return (
        <Container className="Body-container">
            <h3 className='title'>
                {i18n.t('contact_us')}
            </h3>
            <div className='form_container'>
                <Form className='form'>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder={i18n.t('full_name')}
                            id='full_name'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className='input_style'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            placeholder={i18n.t('telephone')}
                            id='telephone'
                            value={telephone}
                            onChange={e => setTelephone(e.target.value)}
                            className='input_style'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type='email'
                            placeholder={i18n.t('email')}
                            id='email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='input_style'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            rows="4"
                            placeholder={i18n.t('message')}
                            id='message'
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            className='input_style'
                        />
                    </Form.Group>

                    <Button
                        variant='flat'
                        onClick={_contactTeam} >
                        {isLoading ? <DisplayLoading /> : i18n.t('send')}
                    </Button>
                </Form>
            </div>
        </Container>
    );
}

export default Contact;