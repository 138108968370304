import React from 'react';
import {Spinner} from 'react-bootstrap';

const DisplayLoading = () => {
    return (
        <Spinner animation="border" variant="green" />
    );
}

export default DisplayLoading;
