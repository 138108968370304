import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import 'react-chat-elements/dist/main.css';
import i18n from '../../Utils/i18n';
import ChatIcon from '../../assets/chat.svg';
import UserView from './UserView';
import Message from './Message';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const Messages = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const language = useSelector(state => state.language.language);
    const _conversations = useSelector(state => state.conversations);
    const userData = useSelector(state => state.userData);
    const [chatDisplay, setChatDisplay] = useState('');
    const [messageDisplay, setMessageDisplay] = useState('none');
    const [showMessages, setShowMessages] = useState(false);
    const [selectedUserIndex, setSelectedUserIndex] = useState(1000);
    const [needsBackButton, setNeedsBackButton] = useState(false);
    const [targetUser, setTargetUser] = useState({});

    const {conversations, latest_conversations} = _conversations;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.innerWidth < 992) {
            setMessageDisplay('none');
        } else {
            setMessageDisplay('');
        }
    }, []);

    useEffect(() => {
        if(location.state?.user) {
            startChat();
        }
    }, [location]);

    const startChat = () => {
        const data = location.state;
        if(latest_conversations.find(c => c.user.id === data.user.id)) {
            // users were in touch
            onChatClicked(data, false);
        } else {
            // new conversation
            let message = {
                to: data.user.id,
                from: userData.userData?.id,
                avatar: data.avatar,
                date: +new Date(),
                subtitle: '',
                title: data.user.name,
                unread: 0,
                type: 'text',
                user: { id: data.user.id, name: data.user.name }
            };

            dispatch({type: 'ADD_NEW_CONVERSATION', data: [message]});
            onChatClicked(data, true);
        }
    }

    const onChatClicked = async(e, isNewConversation) => {
        if (window.innerWidth < 992) {
            setChatDisplay('none');
            setMessageDisplay('');
            setNeedsBackButton(true);
        }

        if(isNewConversation) {
            setTargetUser({id: e.user.id, name: e.user.name, avatar: e.avatar});
            setSelectedUserIndex(0);
        } else {
            for (let index = 0; index < latest_conversations.length; index++) {
                if (latest_conversations[index].user.id === e.user.id) {
                    setTargetUser({id: e.user.id, name: e.user.name, avatar: e.avatar});
                    setSelectedUserIndex(index);
                }
            }
        }
        setShowMessages(true);
    }

    const onBackButton = () => {
        setMessageDisplay('none');
        setChatDisplay('');
    }

    return (
        <Container className='Body-container message_component'>
            <h3 className='title'>
                {i18n.t('messages')}
            </h3>

            {!userData.userData?.id ?
                <div className='not_connected_card'>
                    <div className='_card'>
                        <img src={require('../../assets/logo.png')} alt='app-logo' className='img' />
                        <p className='label'>{i18n.t('youre_not_connected')}</p>
                        <Button
                            variant='flat'
                            onClick={props.openModal.bind(this, 'signin')} >
                            <small>{i18n.t('signin')}</small>
                        </Button>
                    </div>
                </div>
            :
                <Container className='message_container'>
                {latest_conversations && latest_conversations.length > 0 ?
                    <Container>
                        {<UserView
                            latest_conversations={latest_conversations}
                            chatDisplay={chatDisplay}
                            onChatClicked={e => onChatClicked(e, false)}
                            language={language}
                        />}
                        {<Message
                            latest_conversations={latest_conversations}
                            conversations={conversations}
                            showMessages={showMessages}
                            messageDisplay={messageDisplay}
                            onBackButton={onBackButton}
                            selectedUserIndex={selectedUserIndex}
                            userData={userData}
                            targetUser={targetUser}
                            language={language}
                            needsBackButton={needsBackButton}
                        />}
                    </Container>
                :
                    <div className='no_messages_card'>
                        <div className='_card'>
                            <img src={ChatIcon} alt='chat icon' className='img' />
                            <p className='label'>{i18n.t('no_messages')}</p>
                        </div>
                    </div>
                }
                </Container>
            }
        </Container>
    );
}

export default Messages;
