const initialState = {messages: []};

function message(state = initialState, action ) {
    let nextState;
    switch (action.type) {
        case 'SAVE_MESSAGES':
            //nextState = { ...state, messages: [...state.messages, action.data]};
            nextState = { ...state, messages: [...state.messages, action.data]};
            return nextState;
        case 'ADD_MESSAGE':
            nextState = { messages: [action.data]};
            return nextState;
        case 'REMOVE_MESSAGES':
            delete state.messages;
            state = initialState;
            return state;
        default:
            return state;
    }
}

export default message;
