const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export function register(user) {
    const url = `${REACT_APP_API_URL}signup`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: user.username,
            email: user.email,
            password: user.password,
            role: 'user',
        }),
    })
    .then((response) => response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function login(email, password) {
    const url = `${REACT_APP_API_URL}login`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password,
        }),
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function addNotRegisteredUser(data) {
    const url = `${REACT_APP_API_URL}non-user/add`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            notification_token: data.notification_token,
            platform: data.platform,
            date: +new Date(),
        }),
    })
    .then((response) => response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function getNewPassword(email) {
    const url = `${REACT_APP_API_URL}user/newPassword`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function facebookAuth(code) {
    const url = `${REACT_APP_API_URL}login/facebook`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code,
            origin: 'facebook'
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}
