import React, { useState } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {register} from '../../API/authentication';
import { validateEmail } from '../../Utils/functions';
import { useDispatch } from 'react-redux';
import DisplayLoading from '../Partials/DisplayLoading';

const Register = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const language  = 'fr';
    const _register = () => {
        if(email) {
            if(validateEmail(email)) {
                if(username) {
                    if(password) {
                        if(password.length > 5) {
                            if(confirmPassword) {
                                if(password === confirmPassword) {
                                    setIsLoading(true);
                                    const user = {email, password, username};
                                    register(user)
                                    .then(res =>  {
                                        if(res.Error) {
                                            showAlert('error', 'error');
                                        } else {
                                            if(res.Message === 'Email Id already registered') {
                                                showAlert('duplicated_email', 'info');
                                            } else {
                                                onSuccess();
                                            }
                                        }
                                    })
                                    .catch(err => showAlert('error', 'error'));
                                } else showAlert('password_not_match', 'info');
                            } else inputError(`${i18n.t('fill_input')} ${i18n.t('confirm_password')}`);
                        } else inputError(i18n.t('password_too_short'));
                    } else inputError(`${i18n.t('fill_input')} ${i18n.t('password')}`);
                } else inputError(`${i18n.t('fill_input')} ${i18n.t('username')}`);
            } else  inputError(i18n.t('wrong_email_format'));
        } else inputError(`${i18n.t('fill_input')} ${i18n.t('email')}`);
    }

    const onSuccess = () => {
        showAlert('account_created', 'success');
        setIsLoading(false);
        setEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('create_account'), message: i18n.t(message), type}});
        setIsLoading(false);
    }

    const inputError = (message) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('create_account'), message, type: 'info'}});
    }

    const handleChange = event => {
        let value = event.target.value;
        let id = event.target.id;

        switch (id) {
            case 'email':
                setEmail(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            show={props.showSignupModal} onHide={() => props.handleModalClose('signup')}
            width='15px'
            aria-labelledby='contained-modal-title-vcenter'
            centered >
            <Modal.Body className='account_container'>
                <h3 className='title'>
                    {i18n.t('create_account')}
                </h3>
                <Container className='body'>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type='email'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('email')}
                                id='email'
                                value={email}
                                onChange={handleChange}
                                className='input'
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type='text'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('username')}
                                id='username'
                                value={username}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group>  
                            <Form.Control
                                type='password'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('password')}
                                id='password'
                                value={password}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type='password'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('confirm_password')}
                                id='confirmPassword'
                                value={confirmPassword}
                                onChange={handleChange} 
                            />
                        </Form.Group>
                    </Form>

                    <Button
                        variant='flat'
                        onClick={_register} >
                        {isLoading ? <DisplayLoading /> : i18n.t('create_account')}
                    </Button>

                    <hr/>

                    <div className='bottom_link' onClick={() => props.openModal('signin')}>
                        {i18n.t('signin')}
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default Register;
