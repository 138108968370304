import React, { useEffect, useState } from 'react';
import { MessageList } from 'react-chat-elements';
import {Button, Image, Form, Container } from 'react-bootstrap';
import BackIcon from '../../assets/back.svg';
import ProfilIcon from '../../assets/user.svg';
import i18n from '../../Utils/i18n';
import { useDispatch, useSelector } from 'react-redux';
import ChatIcon from '../../assets/chat.svg';
import {getDecodedToken} from '../../Utils/functions';
import { markAsRead } from '../../API/message';

const profilImage = process.env.REACT_APP_API_PROFIL_IMG_URL;

const Message = (props) => {
    const dispatch = useDispatch();
    const socket = useSelector(state => state.socket.socket);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);

    const { latest_conversations, conversations, messageDisplay, showMessages, selectedUserIndex, userData, targetUser, needsBackButton, language } = props;

    useEffect(() => {
        loadMessages();
        
        return () => setMessages([]);
    }, [targetUser, latest_conversations]);

    useEffect(() => {
        if (latest_conversations[selectedUserIndex]?.unread > 0 && targetUser) {
            _markAsRead();
        }
    }, [latest_conversations, targetUser]);

    const _markAsRead = () => {
        const {id, token, refresh_token} = userData.userData;
        markAsRead(id, targetUser.id, token, refresh_token)
        .then(res =>  {
            if(res.error) {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'error'}});
            } else {
                if(res.message === 'connexion failed') {
                    dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'error'}});
                } else if(res.Message === 'Token refreshed') {
                    const payload = getDecodedToken(res);
                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                    setIsTokenRefreshed(true)
                } else {
                    dispatch({type: 'MARK_CONVERSATION_AS_READED', data: targetUser.id});
                }
            }
        })
        .catch(err => dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('message'), message: i18n.t('error'), type: 'error'}}));
    };

    useEffect(() => {
        if(isTokenRefreshed) {
            _markAsRead();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);
    
    const createMessage = () => {
        if(message.length > 0) {
            let _message = {
                to: parseInt(targetUser.id),
                from: userData.userData?.id,

                avatar: userData.userProfil?.img_url,
                date: +new Date(),
                subtitle: message,
                title: userData.userData?.username,
                unread: 1,
                type: 'text',
                user: { id: userData.userData?.id, name: userData.userData?.username }
            };
            socket.emit('message', _message);
            addNewConversation(_message);

            setMessage('');
        }
    }

    const loadMessages = () => {
        conversations.map((message, i) => {
            if(message.to === targetUser.id || message.from === targetUser.id){
                appendMessage(message);
            }
        });
    }

    const appendMessage = (message) => {
        let msg;
        if(message.from === userData.userData?.id) {
            msg = {
                date: message.date,
                position: 'right',
                type: message.type,
                text: message.message
            };
        } else if(message.from === targetUser.id) {
            msg = {
                date: message.date,
                position: 'left',
                type: message.type,
                text: message.message
            };
        }
        setMessages(m => [msg, ...m]);
    }

    const addNewConversation = (message) => {
        message.unread = 0;
        latest_conversations.length === 0 ?
            dispatch({type: 'CREATE_NEW_MESSAGE', data: {message: [message], user: {id: targetUser.id, name: targetUser.name, avatar: targetUser.avatar}}})
        :
            dispatch({type: 'CREATE_NEW_MESSAGE', data: {message: [message]}});
    }

    return (
        <Container style={{ display: messageDisplay }} className='message_view_container'>
            {showMessages ?
            <>
                <div className='title_container'>
                    {needsBackButton ? <Image src={BackIcon} alt='Back button' onClick={props.onBackButton} className='back_btn' /> : null}

                    {!latest_conversations[selectedUserIndex]?.avatar ?
                        <Image src={ProfilIcon} alt='profil photo' roundedCircle className='avatar' />
                    : 
                        <Image src={`${profilImage}${latest_conversations[selectedUserIndex]?.avatar}`} alt='profil photo' roundedCircle className='avatar' />
                    }

                    {!latest_conversations[selectedUserIndex]?.title ?
                        <small>Anonyme</small>
                    :
                        <small>{latest_conversations[selectedUserIndex]?.title}</small>
                    }
                </div>
                <div className='message_list_container' >
                    <MessageList
                        className='message-list scrollable'
                        lockable={true}
                        toBottomHeight='100%'
                        dataSource={messages}
                    />
                </div>
                <div className='message_input_container'>
                    <Form.Group className='input_and_btn'>
                        <Form.Control
                            as='textarea'
                            rows='1'
                            style={language === 'ar' ? { direction: 'rtl' } : { direction: 'ltr' }}
                            id='message'
                            placeholder={i18n.t('type_here')}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            className='message_input'
                        />
                    </Form.Group>
                    <Button
                        variant='flat'
                        onClick={createMessage} >
                        {i18n.t('send')}
                    </Button>
                </div>
            </> 
            :
                <div className='no_messages_card'>
                    <div className='_card'>
                        <img src={ChatIcon} alt='chat icon' className='img' />
                        <p className='label'>{i18n.t('app_name')}</p>
                    </div>
                </div>
            }
        </Container>
    );
}

export default Message;
