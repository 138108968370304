const initialState = {title: '', message: '', type: ''};

const sendAlertMessage = (state = initialState, action ) => {
    let nextState = new Object();
    switch (action.type) {
        case 'SEND_ALERT_MESSAGE':
            nextState.title = action.data.title;
            nextState.message = action.data.message;
            nextState.type = action.data.type;
            return nextState;
        case 'MARK_ALERT_AS_SEEN':
            nextState = initialState;
            return nextState;
        default:
            return state;
    }
}

export default sendAlertMessage;
