import React, { useState, useEffect } from 'react';
import { Container, Form, Modal, Button} from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {getDecodedToken} from '../../Utils/functions';
import {addPhoneNumber} from '../../API/user';
import DisplayLoading from '../Partials/DisplayLoading';
import { useDispatch } from 'react-redux';

const AddPhoneNumber = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [telephone, setTelephone] = useState('');
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
    const data = props.data;

    const _addPhoneNumber = () => {
        const {token, refresh_token} = data;
        const user_id = data.connectedUserId;
        
        if(telephone) {
            setIsLoading(true);
            addPhoneNumber(telephone, user_id, token, refresh_token)
            .then(res =>  {
                if(res.Error) {
                    showAlert('error', 'error');
                } else {
                    if(res.Message === 'Token refreshed') {
                        const payload = getDecodedToken(res);
                        const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                        setIsTokenRefreshed(true);
                    } else onSuccess();
                }
            })
            .catch(err => showAlert('error', 'error'));
        } else {
            showAlert('fill_input', 'error');
        }
    }

    const onSuccess = () => {
        closeModal();
        showAlert('phone_number_added', 'success');
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('image_updating'), message: i18n.t('image_updated'), type: 'success'}});
        props.setPhoneNumberStatusAsAdded();
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('telephone'), message: i18n.t(message), type}});
        setIsLoading(false);
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            _addPhoneNumber();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const closeModal = () => {
        setTelephone('');
        setIsLoading(false);
        props.closeAddPhoneNumberModal();
    }

    const handleChange = event => setTelephone(event.target.value);

    return (
        <Modal
            show={props.showAddPhoneNumberModal} onHide={closeModal}
            width="15px"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body className='account_container'>
                <h3 className='title'>
                    {i18n.t('adding_phone_number')}
                </h3>
                <Container className="body">
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type="number"
                                placeholder={i18n.t('telephone')}
                                id='telephone'
                                value={telephone}
                                onChange={handleChange} />
                        </Form.Group>
                    </Form>

                    <div style={{textAlign: 'center'}}>
                        <Button
                            variant="flat"
                            onClick={_addPhoneNumber} >
                            {isLoading ? <DisplayLoading /> : i18n.t('save')}
                        </Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default AddPhoneNumber;
