import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Fade, Bounce} from 'react-reveal';

const NotificationContainer = (props) => {
    const dispatch = useDispatch();
    const language = useSelector(state => state.language.language);
    const {title, message, type} = props.alertData;

    useEffect(() => {
        if(message)
            setTimeout(() => {
                dispatch({type: 'MARK_ALERT_AS_SEEN'});
            }, 6000);
    }, [message]);

    return (
        message &&
        <div className='notification_container' dir={language === 'ar' ? 'rtl' : 'ltr'} onClick={() => props.hideNotificationContainer()}>
            <Bounce right when={message}>
                <div className={`msg_container ${type}`}>
                    <div className='msg'>
                        <div className='title'>{title}</div>
                        <div className='message'>{message}</div>
                    </div>
                </div>
            </Bounce>
        </div>
    );
}

export default NotificationContainer;
