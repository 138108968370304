import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

const img = (img) => {return process.env.REACT_APP_API_PUBLICATION_IMG_URL + img.url};

const ImagesContainer = (props) => {
    const [index, setIndex] = useState(0);
    const images = props.images;

    const handleSelect = (selectedIndex, e) => setIndex(selectedIndex);

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {images && images.map((image, key) => {
                return (
                    <Carousel.Item key={key}>
                        <img
                            className='carousel-img'
                            src={img(image)}
                            alt='First slide'
                        />
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}

export default ImagesContainer
