import React, { useState, useEffect } from 'react';
import { Container, Modal, Button, Image } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {getDecodedToken} from '../../Utils/functions';
import {updateProfilPicture, deleteProfilPicture} from '../../API/user';

import { StyledDropZone } from 'react-drop-zone';
import 'react-drop-zone/dist/styles.css';

import axios from 'axios';

import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DisplayLoading from '../Partials/DisplayLoading';
import { useDispatch } from 'react-redux';

const UpdateProfilPicture = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState('');
    const [loadedImage, setLoadedImage] = useState('');
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
    const data = props.data;

    const _updateProfilPicture = () => {
        if(image) {
            setIsLoading(true);
            const {token, refresh_token} = data;
            const user_id = data.connectedUserId;
            const date = +new Date();
            const _data = new FormData();
            const img_url = `${date}-${user_id}.png`
            _data.append('file', image, img_url);

            updateProfilPicture(user_id, img_url, token, refresh_token)
            .then((res) => {
                if(res.Error) {
                    showAlert('error', 'error');
                } else {
                    if(res.Message === 'Token refreshed') {
                        const payload = getDecodedToken(res);
                        const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                        setIsTokenRefreshed(true);
                    } else {
                        axios.post(process.env.REACT_APP_API_URL + 'img/upload/profil', _data, {})
                        .then((res) => {
                            if(res.data.Error) {
                                deleteProfilPicture(user_id, token)
                                .then(res => showAlert('error', 'error'))
                                .catch(err => showAlert('error', 'error'))
                            } else {
                                if(res.data.Message === 'Token refreshed') {
                                    const payload = getDecodedToken(res);
                                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                                    setIsTokenRefreshed(true);
                                } else onSuccess(img_url);
                            }
                        })
                        .catch(err => showAlert('error', 'error'));
                    }
                }
            })
        } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('image_updating'), message: i18n.t('add_image'), type: 'info'}});
    }

    const onSuccess = (img_url) => {
        closeModal();
        dispatch({type: 'UPDATE_USER_PROFIL_IMG', data: {img_url}});
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('image_updating'), message: i18n.t('image_updated'), type: 'success'}});
        props.updateProfilPictureUrl(img_url);
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('image_updating'), message: i18n.t(message), type}});
        setIsLoading(false);
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            _updateProfilPicture();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const removeImage = () => {
        setToInitialState();
    }

    const closeModal = () => {
        setToInitialState();
        props.closeProfilPictureModal();
    }

    const onChangeHandler = (file) => {
        setImage(file);
        setLoadedImage(URL.createObjectURL(file));
    }

    const setToInitialState = () => {
        setImage('');
        setLoadedImage('');
        setIsLoading(false);
    }

    return (
        <Modal
            show={props.showProfilPictureModal} onHide={closeModal}
            width="15px"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body className='profil_picture_modal'>
                <h3>
                    {i18n.t('image_updating')}
                </h3>
                <Container className="w-75 container">
                    <StyledDropZone 
                        onDrop={onChangeHandler}
                        children={image ? image.name : i18n.t('drop_your_image_here')} />
                    <div className='img_preview_container'>
                        {image ?
                            <div>
                                <Image src={loadedImage} className="img_preview" alt="profil photo" roundedCircle />
                                <FontAwesomeIcon className="ml-2" icon={faWindowClose} color='#34ebc0' onClick={removeImage} />
                            </div>
                        : null}
                    </div>

                    <div className='btn_container'>
                        <Button variant='flat' onClick={_updateProfilPicture}>
                            {isLoading ? <DisplayLoading /> : i18n.t('save')}
                        </Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateProfilPicture;
