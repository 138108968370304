const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export function userProfil(user_id) {
    const url = `${REACT_APP_API_URL}user/profil?user_id=${user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function userProfilForMessage(user_id) {
    const url = `${REACT_APP_API_URL}user/profilMessage?user_id=${user_id}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function addPhoneNumber(telephone, user_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}user/addPhoneNumber`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token
        },
        body: JSON.stringify({
            telephone,
            user_id
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function showHidePhoneNumber(telephone_status, user_id, token, refresh_token) {
    const url = `${REACT_APP_API_URL}user/showHidePhoneNumber`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token
        },
        body: JSON.stringify({
            telephone_status,
            user_id
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function getNewPassword(email) {
    const url = `${REACT_APP_API_URL}user/newPassword`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function updateProfilPicture(user_id, img_url, token, refresh_token) {
    const url = `${REACT_APP_API_URL}user/updateProfilImg`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token, refresh_token
        },
        body: JSON.stringify({
            user_id, img_url
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function deleteProfilPicture(user_id, token) {
    const url = `${REACT_APP_API_URL}user/deleteProfilImg`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token
        },
        body: JSON.stringify({
            user_id
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}

export function contactTeam(data) {
    const url = `${REACT_APP_API_URL}user/contactTeam`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: data.name,
            telephone: data.telephone,
            email: data.email,
            message: data.message,
        })
    })
    .then((response) =>  response.json())
    .catch((error) => {
        if(error.message === 'Network request failed') {
            const err = {message: 'connexion failed'};
            return err;
        }
    });
}
