const initialState = {language: 'fr', dir: 'ltr'};

const language = (state = initialState, action ) => {
    let nextState = {};
    switch (action.type) {
        case 'SET_LANGUAGE':
            nextState.language = action.data.language;
            nextState.dir = action.data.dir;
            return nextState;
        case 'SET_DEFAULT_LANGUAGE':
            nextState = initialState;
            return nextState;
        default:
            return state;
    }
}

export default language;
