import React, { useState } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {getNewPassword} from '../../API/authentication';
import { validateEmail } from '../../Utils/functions';
import { useDispatch } from 'react-redux';
import DisplayLoading from '../Partials/DisplayLoading';

const ForgottenPassword = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const language  = 'fr';
    const _getNewPassword = () => {
        if(email) {
            if(validateEmail(email)) {
                setIsLoading(true);
                getNewPassword(email)
                .then(res =>  {
                    if(res.Error) {
                        showAlert('error', 'error');
                    } else {
                        if(res.Message === 'Email not registered') {
                            showAlert('email_not_registered', 'info');
                        } else if(res.Message === 'account created with facebook') {
                            showAlert('account_created_with_facebook', 'info');
                        }else {
                            onSuccess();
                        }
                    }
                })
                .catch(err => showAlert('error', 'error'));
            } else  inputError(i18n.t('wrong_email_format'));
        } else inputError(`${i18n.t('fill_input')} ${i18n.t('email')}`);
    }

    const onSuccess = () => {
        showAlert('password_sent', 'success');
        setIsLoading(false);
        setEmail('');
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('password'), message: i18n.t(message), type}});
        setIsLoading(false);
    }

    const inputError = (message) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('password'), message, type: 'info'}});
    }

    const handleChange = event => {
        if(event.target.id === 'email') setEmail(event.target.value);
    }

    return (
        <Modal
            show={props.showForgotPasswordModal} onHide={() => props.handleModalClose('forgotpassword')}
            width='15px'
            aria-labelledby='contained-modal-title-vcenter'
            centered >
            <Modal.Body className='account_container'>
                <h3 className='title'>
                    {i18n.t('password_forgotten')}
                </h3>
                <Container className='body'>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type='email'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('email')}
                                id='email'
                                value={email}
                                onChange={handleChange}
                                className='input'
                            />
                        </Form.Group>
                    </Form>

                    <Button
                        variant='flat'
                        onClick={_getNewPassword} >
                        {isLoading ? <DisplayLoading /> : i18n.t('get_new_password')}
                    </Button>

                    <div className='bottom_link' onClick={() => props.openModal('signin')}>
                        {i18n.t('signin')}
                    </div>
                    
                    <hr/>

                    <div className='bottom_link' onClick={() => props.openModal('signup')}>
                        {i18n.t('create_account')}
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ForgottenPassword;
