import React, { useState } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {login} from '../../API/authentication';
import {userProfil} from '../../API/user';
import { getDecodedToken, validateEmail } from '../../Utils/functions';
import { useDispatch } from 'react-redux';
import DisplayLoading from '../Partials/DisplayLoading';

const facebookLogin = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT}&redirect_uri=${process.env.REACT_APP_URL}authenticate/facebook`;

const Login = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const language  = 'fr';
    const _login = () => {
        if(email) {
            if(validateEmail(email)) {
                if(password) {
                    if(password.length > 5) {
                        setIsLoading(true);
                        login(email, password)
                        .then(res =>  {
                            if(res.Error) {
                                showAlert('error', 'error');
                            } else {
                                if(res.Message === 'wrong email/password combination') {
                                    showAlert('incorrect_email_password', 'info');
                                } else if(res.Message === 'user bloqued') {
                                    showAlert('account_bloqued', 'info');
                                } else if(res.Message === 'email not verified') {
                                    showAlert('email_not_verified', 'info');
                                } else {
                                    storeUserData(res);
                                }
                            }
                        })
                        .catch(err => showAlert('error', 'error'));
                    } else inputError(i18n.t('password_incorrect'));
                } else inputError(`${i18n.t('fill_input')} ${i18n.t('password')}`);
            } else  inputError(i18n.t('wrong_email_format'));
        } else inputError(`${i18n.t('fill_input')} ${i18n.t('email')}`);
    }

    const setUserProfil = (userId) => {
        userProfil(userId)
        .then(res =>  {
            if(res.Error) {
                showAlert('error', 'error')
            } else {
                dispatch({type: 'UPDATE_USER_PROFIL', data: res.profil[0]});
            }
        })
        .catch(err => showAlert('error', 'error'));
    }

    const storeUserData = async(res) => {
        const data = getDecodedToken(res);
        dispatch({type: 'UPDATE_USER_DATA', data});
        setUserProfil(data.id);
        showAlert('youre_connected', 'success');
        setIsLoading(false);
        setEmail('');
        setPassword('')
        props.handleModalClose('signin');
    }

    const showAlert = (message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('connexion'), message: i18n.t(message), type}});
        setIsLoading(false);
    }

    const inputError = (message) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('connexion'), message, type: 'info'}});
    }

    return (
        <Modal
            show={props.showSigninModal} onHide={() => props.handleModalClose('signin')}
            width='15px'
            aria-labelledby='contained-modal-title-vcenter'
            centered >
            <Modal.Body className='account_container'>
                <h3 className='title'>
                    {i18n.t('connexion')}
                </h3>
                <Container className='body'>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type='email'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('email')}
                                id='email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                className='input'
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type='password'
                                style={language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
                                placeholder={i18n.t('password')}
                                id='password'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                className='input'
                            />
                        </Form.Group>
                    </Form>

                    <Button
                        variant='flat'
                        onClick={_login} >
                        {isLoading ? <DisplayLoading /> : i18n.t('signin')}
                    </Button>

                    <div className='bottom_link' onClick={() => props.openModal('forgotpassword')}>
                        <small>{i18n.t('password_forgotten')}</small>
                    </div>
                    
                    <Button
                        variant='flat'
                        className='facebook_login'>
                        <a href={facebookLogin}>
                            {i18n.t('continue_with_facebook')}
                        </a>
                    </Button>

                    <hr/>

                    <div className='bottom_link' onClick={() => props.openModal('signup')}>
                        {i18n.t('create_account')}
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default Login;
