import _ from 'underscore';

const initialState = {conversations: [], latest_conversations: []};

const conversation = (state = initialState, action ) => {
    let nextState;
    switch (action.type) {
        case 'UPDATE_CONVERSATIONS':
            let __conversations = [];
            action.data.map(conversation => {
                if(state.conversations.find(c => c && (c.date === conversation.date && c.to === conversation.to && conversation.from === c.from))) {
                    return;
                } else {
                    return __conversations.push(conversation);
                }
            });
            nextState = { ...state, conversations: [...__conversations, ...state.conversations]};
            return nextState;
        case 'UPDATE_LATEST_CONVERSATIONS':
            nextState = { ...state, latest_conversations: _.uniq([...action.data, ...state.latest_conversations], true, i => i.user.id)};
            return nextState;
        case 'UPDATE_LATEST_CONVERSATIONS_ADD_NEW_MESSAGE':
            return nextState;
        case 'ADD_NEW_MESSAGE':
            const action_data = action.data[0];
            const data = state.latest_conversations.filter(u => u.user.id !== action_data.user.id);
            const conversation = [{type: action_data.type, message: action_data.subtitle, date: action_data.date, from: action_data.from, to : action_data.to, unread: 1}];
            nextState = { ...state, latest_conversations: [...data, ...action.data], conversations: [...conversation, ...state.conversations]};
            return nextState;
        case 'CREATE_NEW_MESSAGE':
            const _action_data = action.data.message[0];
            const new_user = action.data.user;
            let update_conversation;
            if(new_user) {
                update_conversation = [{from: _action_data.from, to: _action_data.to, avatar: new_user.avatar, unread: 0, date: _action_data.date, subtitle: _action_data.subtitle, title: _action_data.title, type: _action_data.type, user: {id: new_user.id, name: new_user.name}}];
            } else {
                update_conversation = state.latest_conversations.map(({subtitle, date, ...rest}) => 
                rest.user.id === _action_data.from || rest.user.id === _action_data.to ?
                    ({...rest, subtitle: _action_data.subtitle, date: _action_data.date}) :
                {...rest, subtitle, date});
            }
            const new_conversation = [{type: _action_data.type, message: _action_data.subtitle, date: _action_data.date, from: _action_data.from, to : _action_data.to, unread: 1}];
            nextState = { ...state, latest_conversations: [...update_conversation], conversations: [...new_conversation, ...state.conversations]};
            return nextState;
        case 'ADD_NEW_CONVERSATION':
            nextState = { ...state, latest_conversations: [...action.data, ...state.latest_conversations]};
            return nextState;
        case 'UPDATE_CONVERSATIONS_ON_UNREAD_MESSAGE_CHECK':
            let _latest_conversations = [], _conversations = [];
            _latest_conversations = _.uniq([...action.data.last_conversations, ...state.latest_conversations], true, i => i.user.id);
            action.data.conversations.map(conversation => {
                if(state.conversations.find(c => c.date === conversation.date && c.to === conversation.to && conversation.from === c.from)) {
                    return;
                } else {
                    return _conversations.push(conversation);
                }
            });
            nextState = { ...state, latest_conversations: [..._latest_conversations], conversations: [..._conversations, ...state.conversations]};
            return nextState;
        case 'ADD_MESSAGE':
            nextState = { conversations: [action.data]};
            return nextState;
        case 'REMOVE_MESSAGES':
            state = initialState;
            return state;
        case 'MARK_CONVERSATION_AS_READED':
            const c = state.latest_conversations.map(({unread, ...rest}) => rest.user.id === action.data ? ({...rest, unread: 0}) : {...rest, unread});
            nextState = { ...state, latest_conversations: [...c]};
            return nextState;
        case 'MARK_CONVERSATIONS_AS_READED':
            const latest_conversations = state.latest_conversations.map(({unread, ...rest}) => ({...rest, unread: 0}));
            nextState = { ...state, latest_conversations: [...latest_conversations]};
            return nextState;
        default:
            return state;
    }
}

export default conversation;
