const initialState = { pViews: [] };

function publicationData(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'MARK_AS_VIEWED':
            nextState = { ...state, pViews: [...state.pViews, action.data]};
            return nextState;
        case 'REMOVE_VIEWS':
            delete state.pViews;
            state = {pViews: []};
            return state;
        default:
            return state;
    }
}

export default publicationData;
