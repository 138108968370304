import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Container, Image, Col } from 'react-bootstrap';
import i18n from '../Utils/i18n';
import PlaystoreLogo from '../assets/playstore.svg';
import AppleStoreLogo from '../assets/app.svg';
import {categories} from '../Utils/functions';

const Home = () => {
    const history = useHistory();

    return (
        <Container className='Body-container home'>
            <Container>
                <p className='introduction'>
                    {i18n.t('introduction')}
                </p>

                <div className='download_app'>
                    <img src={PlaystoreLogo} alt='Google playstore Logo' />
                    <a className='label' href='https://play.google.com/store/apps/details?id=com.aryilo'>
                        {i18n.t('download_app')}
                    </a>
                </div>
                <div className='download_app'>
                    <img src={AppleStoreLogo} alt='Apple store Logo' />
                    <a className='label' href='https://apps.apple.com/fr/app/aryilo/id1557239219'>
                        {i18n.t('download_app')}
                    </a>
                </div>
            </Container>

            <h1 className='title'>
                {i18n.t('home_message')}
            </h1>

            <Container className='categories'>
                <Row>
                    {categories.map((category, key) => {return(
                        <Col  xs={6} md={4} key={key} className='col_card'
                            onClick={() => history.push({pathname: '/publications', search: '?category=' + category.id,  state:{id: category.id, title: category.name}})} >
                            <Image src={category.logo} alt='Logo' thumbnail />
                            <p className='label'>{i18n.t(category.name)}</p>
                        </Col>
                    )})}
                </Row>
            </Container>
        </Container>
    );
}
export default Home;
