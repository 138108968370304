import React, {useState, useEffect} from 'react';
import i18n from '../../Utils/i18n';
import { Button, Image, Row, Col, Container } from 'react-bootstrap';
import ProfilIcon from '../../assets/user.svg';
import { isMobile } from 'react-device-detect';
import UpdateProfilPicture from './UpdateProfilPicture';
import { useDispatch, useSelector } from 'react-redux';
import {getDecodedToken} from '../../Utils/functions';
import {showHidePhoneNumber} from '../../API/user';
import AddPhoneNumber from './AddPhoneNumber';
import { useHistory, useLocation } from 'react-router';

let profilImg = (img) => {return process.env.REACT_APP_API_PROFIL_IMG_URL + img};

const ProfilPanel = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const socket = useSelector(state => state.socket.socket);
    const [showProfilPictureModal, setShowProfilPictureModal] = useState(false);
    const [showAddPhoneNumberModal, setShowAddPhoneNumberModal] = useState(false);
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);

    let data = props.data;
    let telePhoneStatus;

    const sedMessage = () => {
        if(data.connectedUserId) {
            history.push({pathname: '/messages', state:{
                user: {id: data.user_id, name: data.username}, avatar: data.img_url,
            }});
        } else {
            showAlert('message', 'youre_not_connected', 'info');
        }
    }
    
    const logOut = () => {
        if(socket && socket.connected) socket.disconnect();
        dispatch({type: 'USER_LOGOUT'});
        history.push('/');
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('app_name'), message: i18n.t('youre_disconnected'), type: 'info'}});
    }

    const _showHidePhoneNumber = (telephone_status) => {
        const {token, refresh_token} = data;
        const user_id = data.connectedUserId;
        telephone_status = !telephone_status ? '2' : telephone_status;

        showHidePhoneNumber(telephone_status, user_id, token, refresh_token)
        .then(res =>  {
            if(res.Error) {
                showAlert('telephone', 'error', 'error');
            } else {
                if(res.Message === 'Token refreshed') {
                    const payload = getDecodedToken(res);
                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                    setIsTokenRefreshed(true);
                } else {
                    data.telephone_status = telephone_status;
                    (location?.pathname === '/profil' || location?.pathname) && props.triggerPropsChange();
                    showAlert('telephone', telephone_status === '1' ? 'your_phone_number_is_visible' : 'your_phone_number_is_invisible', 'success');
                }
            }
        })
        .catch(err => showAlert('telephone', 'error', 'error'));
    }

    const handlePhoneNumber = () => {
        if(data.telephone_status === '0') {
            setShowAddPhoneNumberModal(true);
        } else if(data.telephone_status === '2') {
            telePhoneStatus = '1';
            _showHidePhoneNumber('1');
        }
    } 

    const showAlert = (title, message, type) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t(title), message: i18n.t(message), type}});
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            _showHidePhoneNumber(telePhoneStatus);
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);
    
    const makeCall = (telephone, telephone_status) => {
        if(telephone && telephone_status === '1') {
            if(isMobile){
                window.open(`tel:${telephone}`)
            } else {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('telephone'), message: telephone, type: 'info'}});
            }
        } else {
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('telephone'), message: i18n.t('user_doesnt_have_fill_phone_number'), type: 'info'}});
        }
    }
    
    const onUserClick = () => {
        if(data.connectedUserId && data.connectedUserId === data.user_id) {
            // change user profil
            setShowProfilPictureModal(true);
        } else {
            // go to user profil
            history.push({pathname: '/profil', search: '?id='+data.user_id,  state:{id: data.user_id}});
        }
    }

    const closeProfilPictureModal = () => setShowProfilPictureModal(false);
    const updateProfilPictureUrl = (img_url) => {
        data.img_url = img_url;
        (location?.pathname === '/profil' || location?.pathname) && props.triggerPropsChange();
    }

    const closeAddPhoneNumberModal = () => setShowAddPhoneNumberModal(false);
    const setPhoneNumberStatusAsAdded = () => {
        data.telephone_status = '1';
        (location?.pathname === '/profil' || location?.pathname) && props.triggerPropsChange();
    }

    return (
        <Container className='profil_container'>
            <Row className='profil_row'>
                <Col className='profil_col' onClick={onUserClick}>
                    {data.img_url ?
                        <Image src={profilImg(data.img_url)} alt='profil photo' roundedCircle />
                    : 
                        <Image src={ProfilIcon} alt='profil photo' roundedCircle />
                    }
                    <small> {data.username}</small>
                </Col>
                
                <Col className='profil_col'>
                    {data.user_id && data.connectedUserId && data.user_id === data.connectedUserId ?
                        <Button
                            variant='flat'
                            onClick={logOut} >
                            <small>{i18n.t('sign_out')}</small>
                        </Button>
                    :  <Button
                            variant='flat'
                            onClick={() => makeCall(data.telephone, data.telephone_status)} >
                            <small>{i18n.t('make_call')}</small>
                        </Button>   
                    }
                </Col>

                <Col className='profil_col bigger'>
                    {data.user_id && data.connectedUserId && data.user_id === data.connectedUserId ? (
                        data.telephone && data.telephone_status && data.telephone_status === '1' ?
                            <Button
                                variant='flat'
                                onClick={() => _showHidePhoneNumber('2')} >
                                <small>{i18n.t('hide_phone_number')}</small>
                            </Button>
                        : 
                            <Button
                                variant='flat'
                                onClick={handlePhoneNumber} >
                                <small>{i18n.t('show_phone_number')}</small>
                            </Button>
                    ):  <Button
                            variant='flat'
                            onClick={sedMessage} >
                            <small>{i18n.t('send_message')}</small>
                        </Button>
                    }
                </Col>
            </Row>
            <UpdateProfilPicture
                data={data}
                showProfilPictureModal={showProfilPictureModal}
                closeProfilPictureModal={closeProfilPictureModal}
                updateProfilPictureUrl={updateProfilPictureUrl}
            />

            <AddPhoneNumber
                data={data}
                showAddPhoneNumberModal={showAddPhoneNumberModal}
                closeAddPhoneNumberModal={closeAddPhoneNumberModal}
                setPhoneNumberStatusAsAdded={setPhoneNumberStatusAsAdded}
            />
        </Container>
    );
}

export default ProfilPanel;
