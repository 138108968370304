import React, { useEffect, useState } from 'react';
import { Pagination, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {searchByProductName} from '../../API/publication';
import i18n from '../../Utils/i18n';
import PublicationCard from './PublicationCard';
import { useDispatch } from 'react-redux';

const SearchResults = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [publications, setPublications] = useState();
    const [pages, setPages] = useState(0);
    const [active, setActive] = useState(1);

    const search = location.search.substring(8);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadResults(active -1);
    }, [location]);

    const loadResults = (pageNo) => {
        searchByProductName(search, pageNo)
        .then(res =>  {
            if(res.Error) {
                setPublications([]);
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            } else if(res.publication.length < 0) {
                setPublications([]);
            } else {
                setPublications(res.publication);
                setPages(res.page);
            }
        })
        .catch(err => {
            setPublications([]);
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
        });
    }

    const pagination = () => {
        let items = [];
        for(let i = 1; i <= pages; i ++) {
            items.push(
                <Pagination.Item onClick={() => {setActive(i); loadResults(i-1)}} key={i} active={i === active}>
                    {i}
                </Pagination.Item>
            );
        }
        return (
            <Pagination size='sm' dir='ltr' onClick={() => pagination()}>
                {items}
            </Pagination>
        );
    }

    return (
        <Container className='Body-container search_results'>
            <h1 className='title'>
                {i18n.t('search_result')}
            </h1>
            <Row className='publication_row'>
                {publications && publications.map((publication, index) =>
                    <PublicationCard publication={publication} key={index} />
                )}
            </Row>
            {
                publications && publications.length < 1 ? 
                    <div className='no_search_results'>
                        <small>{i18n.t('no_search_results')}</small>
                    </div>
            : null }
            {pages > 1 ? pagination() : null}
        </Container>
    );
}

export default SearchResults;
