import React, { useEffect, useState } from 'react';
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import { Nav, Navbar, FormControl, InputGroup, Container } from 'react-bootstrap';
import { faSearch, faHome, faHeart, faUser, faPlusCircle, faComments } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Home from '../Components/Home';
import Favored from '../Components/Publication/Favored';
import Upload from '../Components/Publication/Upload';
import Messages from '../Components/Message/Messages';
import Profil from '../Components/User/Profil';
import Publications from '../Components/Publication/Publications';
import Publication from '../Components/Publication/Publication';
import SearchResults from '../Components/Publication/SearchResults';
import Contact from '../Components/Others/Contact';

import i18n from '../Utils/i18n';

import FacebookLogo from '../assets/facebook.svg';
import IntagramLogo from '../assets/instagram.svg';
import TwitterLogo from '../assets/twitter.svg';
import PlaystoreLogo from '../assets/playstore.svg';
import AppleStoreLogo from '../assets/app.svg';

import {Badge} from '@material-ui/core';

import Login from '../Components/User/Login';
import Register from '../Components/User/Register';
import ForgottenPassword from '../Components/User/ForgottenPassword';
import { useDispatch, useSelector } from 'react-redux';
import FacebookAuthentication from '../Components/User/FacebookAuthentication';

const Navigation = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData.userData);
    const history = useHistory();
    const [navExpanded, setNavExpanded] = useState(false);
    const [showSigninModal, setShowSigninModal] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [search, setSearch] = useState('');
    const [windowWidth, setWindowWidth] = useState(null);

    const language = props.language;

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResize = () => setWindowWidth(window.innerWidth);

    const handleClick = (language) => {
        const dir = language === 'ar' ? 'rtl' : 'ltr';
        i18n.locale = language;
        dispatch({type: 'SET_LANGUAGE', data: {language, dir}});
    }

    const handleChange = (event) => {
        let value = event.target.value;
        if(event.target.id === 'search') {
            setSearch(value);
            renderSearchResults(value);
        }
    }

    const renderSearchResults = (search) => {
        if(search.length > 2) {
            history.push({pathname: '/search', search: `?search=${search}`});
        }
    }

    const handleModalClose = (name) => {
        if(name === 'signin') setShowSigninModal(false)
        else if(name === 'signup') setShowSignupModal(false);
        else if(name === 'forgotpassword') showForgotPasswordModal(false);
    }

    const openModal = (name) => {
        if(name === 'signin') {
            setShowSigninModal(true);
            setShowSignupModal(false);
            setShowForgotPasswordModal(false);
        } else if(name === 'signup') {
            setShowSignupModal(true);
            setShowSigninModal(false);
            setShowForgotPasswordModal(false);
        } else if(name === 'forgotpassword') {
            setShowForgotPasswordModal(true);
            setShowSigninModal(false);
            setShowSignupModal(false);
        }
    }

    const _setNavExpanded = () => {
        if(navExpanded) setNavExpanded(false);
        else setNavExpanded(true);
    }

    return (
        <div className="Nav-container" dir={props.dir}>
            <div>
            <Navbar dir="ltr" collapseOnSelect expand="lg" className="Nav-bar navigation" variant="dark" fixed="top" 
            onToggle={_setNavExpanded}
            expanded={navExpanded}  >
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={require("../assets/logo.png")}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="loumé-logo"
                    />
                </Navbar.Brand>
                {windowWidth > 991 ? (
                    <Nav className="mr-auto Search-element">
                        <InputGroup>
                            <InputGroup.Prepend style={{backgroundColor: '#34ebc0'}}>
                                <InputGroup.Text style={{backgroundColor: '#0f1724', borderColor: '#34ebc0'}}>
                                    <FontAwesomeIcon icon={faSearch} color='#34ebc0' />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                style={language === 'ar' ? {color: 'white', backgroundColor: '#0f1724', borderColor: '#34ebc0', textAlign: 'right'} : {color: 'white', backgroundColor: '#0f1724', borderColor: '#34ebc0', textAlign: 'left'}}
                                type="text"
                                placeholder={i18n.t('search')}
                                className="mr-sm-2"
                                id="search"
                                value={search}
                                onChange={handleChange} />
                        </InputGroup>
                    </Nav>
                ) : (
                    <Nav className="mr-auto w-75">
                        <InputGroup>
                            <InputGroup.Prepend style={{backgroundColor: '#34ebc0'}}>
                                <InputGroup.Text style={{backgroundColor: '#0f1724', borderColor: '#34ebc0'}}>
                                    <FontAwesomeIcon icon={faSearch} color='#34ebc0' />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl 
                                style={language === 'ar' ? {color: 'white', backgroundColor: '#0f1724', borderColor: '#34ebc0', textAlign: 'right'} : {color: 'white', backgroundColor: '#0f1724', borderColor: '#34ebc0', textAlign: 'left'}}
                                type="text"
                                placeholder={i18n.t('search')}
                                className="mr-sm-2" />
                        </InputGroup>
                    </Nav>
                )}
                
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav"> */}
                <Navbar.Collapse id="responsive-navbar-nav" className='navbar_items'>
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <Nav.Link as={Link} to="/ajouter">
                            <Container className="Nav-bar-element navbar_element">
                                <FontAwesomeIcon icon={faPlusCircle} color="#34ebc0" size="xs" />
                                <small> &nbsp;{i18n.t('upload')}</small>
                            </Container>
                        </Nav.Link>

                        <Nav.Link as={Link} to="/messages">
                            <Badge color="secondary" badgeContent={props.newMessages}>
                                <Container className="Nav-bar-element navbar_element">
                                    <FontAwesomeIcon icon={faComments} color="#34ebc0" size="xs" />
                                    <small> &nbsp;{i18n.t('messages')}</small>
                                </Container>
                            </Badge>
                        </Nav.Link>
                        
                        <Nav.Link as={Link} to={userData.id ? `/profil?id=${userData.id}` : ''} onClick={!userData.id && openModal.bind(this, 'signin')} >
                            <Container className="Nav-bar-element navbar_element">
                                <FontAwesomeIcon icon={faUser} color="#34ebc0" size="xs" />
                                <small> &nbsp;{i18n.t('profil')}</small>
                            </Container>
                        </Nav.Link>

                        <Nav.Link as={Link} to="/favorites">
                            <Container className="Nav-bar-element navbar_element">
                                <FontAwesomeIcon icon={faHeart} color="#34ebc0" size="xs" />
                                <small> &nbsp;{i18n.t('favoris')}</small>
                            </Container>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {windowWidth &&  windowWidth < 991 ? (
                <Navbar className="Nav-bar Bottom-nav navigation navigation_mobile" variant="dark" fixed="bottom">
                    <Container>
                    {/* <Nav activeKey={pathname}> */}
                        <Container>
                            <Nav.Link as={Link} to="/">
                                <FontAwesomeIcon icon={faHome} color="#34ebc0" size='xs' />
                            </Nav.Link>
                            <small className='label'>{i18n.t('home')}</small>
                        </Container>

                        <Container>
                            <Nav.Link as={Link} to="/favorites" >
                                <FontAwesomeIcon icon={faHeart} color="#34ebc0" size='xs' />
                            </Nav.Link>
                            <small className='label'>{i18n.t('favoris')}</small>
                        </Container>

                        <Container>
                            <Nav.Link as={Link} to="/ajouter">
                                <FontAwesomeIcon icon={faPlusCircle} color="#34ebc0" size='xs' />
                            </Nav.Link>
                                <small className='label'>{i18n.t('publish')}</small>
                        </Container>

                        <Container>
                            <Nav.Link as={Link} to="/messages">
                                <Badge color="secondary" badgeContent={props.newMessages} >
                                    <FontAwesomeIcon icon={faComments} color="#34ebc0" size='xs' />
                                </Badge>
                            </Nav.Link>
                            <small className='label'>{i18n.t('messages')}</small>
                        </Container>

                        <Container>
                            <Nav.Link as={Link} to={userData.id ? `/profil?id=${userData.id}` : ''} onClick={!userData.id && openModal.bind(this, 'signin')}>
                                <FontAwesomeIcon icon={faUser} color="#34ebc0" size='xs' />
                            </Nav.Link>
                            <small className='label'>{i18n.t('profil_short')}</small>
                        </Container>
                    {/* </Nav> */}
                    </Container>
                </Navbar>
            ) : null}
            
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/favorites" >
                        <Favored openModal={openModal} />
                    </Route>
                    <Route exact path="/ajouter">
                        <Upload openModal={openModal} />
                    </Route>
                    <Route exact path="/messages">
                        <Messages openModal={openModal} />
                    </Route>
                    <Route exact path="/profil">
                        <Profil />
                    </Route>
                    <Route exact path="/authenticate/facebook">
                        <FacebookAuthentication />
                    </Route>
                    <Route exact path="/publications">
                        <Publications />
                    </Route>
                    <Route exact path="/publications/publication">
                        <Publication />
                    </Route>
                    <Route exact path="/search">
                        <SearchResults />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                </Switch>
            </div>

            <div className='footer_container'>
                <Navbar className="Nav-bar footer" variant="dark" sticky="bottom">
                    <Container className="mb-5 top_container">
                        <Container className='column'>
                            <Navbar.Brand href="#home">
                                <img
                                    src={require("../assets/logo.png")}
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    alt="Aryilo-logo"
                                />
                                <small className='label'>
                                    Aryilo
                                </small>
                                
                            </Navbar.Brand>
                            <p className='label'>
                                contact@aryilo.com
                            </p>
                            <div className='images_container'>
                                <a href='https://www.facebook.com/aryiloOfficiel'>
                                    <img src={FacebookLogo} alt="Facebook Logo" />
                                </a>
                                <a href='https://www.instagram.com/aryilo_officiel/'>
                                    <img src={IntagramLogo} alt="Instagram Logo" />
                                </a>
                                <a href='https://twitter.com/aryilo1'>
                                    <img src={TwitterLogo} alt="Twitter Logo" />
                                </a>
                            </div>
                        </Container>
                        <hr />
                        <Container className='column'>
                            <div className='img_label'>
                                <img src={PlaystoreLogo} alt="Google playstore Logo" />
                                <a className='label' href='https://play.google.com/store/apps/details?id=com.aryilo'>{i18n.t('download_app')}</a>
                            </div>
                            <br/>
                            <div className='img_label'>
                                <img src={AppleStoreLogo} alt="Apple store Logo" />
                                <a className='label' href='https://apps.apple.com/fr/app/aryilo/id1557239219'>{i18n.t('download_app')}</a>
                            </div>
                        </Container>
                        <hr/>
                        <Container className='column'>
                            <a className='link'>
                                <small style={{cursor: 'not-allowed'}} className='label'>{i18n.t('FAQ')}</small>
                            </a>
                            <a className='link' href='https://aryilo.com/cgu.html'>
                                <small className='label'>{i18n.t('cg')}</small>
                            </a>
                            <a className='link' href='https://aryilo.com/cgu.html'>
                                <small className='label'>{i18n.t('pc')}</small>
                            </a>
                            <Nav.Link className='link' as={Link} to='/contact'>
                                <small className='label'>{i18n.t('contact_us')}</small>
                            </Nav.Link>
                        </Container>
                        <hr/>
                        <Container className='column'>
                            <a className='link'>
                                <small style={{cursor: 'not-allowed'}} className='label'>{i18n.t('who_we_are')}</small>
                            </a>
                            {/* <a className='link'>
                                <small className='label'>{i18n.t('team')}</small>
                            </a> */}
                            <a className={language === 'fr' ? 'link is_active' : 'link'} onClick={handleClick.bind(this, 'fr')}>
                                <small className='label'>Français</small>
                            </a>
                            <a className={language === 'ar' ? 'link is_active' : 'link'} onClick={handleClick.bind(this, 'ar')}>
                                <small className='label'>العربية</small>
                            </a>
                            <a className={language === 'en' ? 'link is_active' : 'link'} onClick={handleClick.bind(this, 'en')}>
                                <small className='label'>English</small>
                            </a>
                            {/* <p><small style={{fontSize: this.state.footerFontSize}}>&copy; 2020 Aryilo</small><br/></p> */}
                            {windowWidth <991  && <br/>}
                        </Container>
                    </Container>
                </Navbar>
                <div className='copyright'>
                    <small className='label'>&copy; 2021 Aryilo - All rights reserved</small>
                </div>
            </div>

            <Login showSigninModal={showSigninModal} handleModalClose={handleModalClose} openModal={openModal} />
            <Register showSignupModal={showSignupModal} handleModalClose={handleModalClose} openModal={openModal} />
            <ForgottenPassword showForgotPasswordModal={showForgotPasswordModal} handleModalClose={handleModalClose} openModal={openModal} />
        </div>
    );
}

export default Navigation;
