import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {markAsSoldClosed} from '../../../API/publication';
import i18n from '../../../Utils/i18n';
import {getDecodedToken} from '../../../Utils/functions';
import { useDispatch } from 'react-redux';
import ReportModal from './ReportModal';

const CloseOrReportPublication = (props) => {
    const dispatch = useDispatch();
    const [showReportModal, setShowReportModal] = useState(false);
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
    
    const {publication, data, language} = props;

    const markAsSoldOrClosed = () => {
        markAsSoldClosed(publication.user_id, publication.id, data.token, data.refresh_token)
        .then(res =>  {
            if(res.Error) {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            } else {
                if(res.Message === 'Token refreshed') {
                    const payload = getDecodedToken(res);
                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                    setIsTokenRefreshed(true);
                } else {
                    props.setPublicationStatusChange('2');
                }
            }
        })
        .catch(err => dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}}));
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            markAsSoldOrClosed();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const openReportModal = () => setShowReportModal(true);
    const closeReportModal = () => setShowReportModal(false);

    return (
        <div>
            {data.connectedUserId === publication.user_id ?
                publication.publication_status === '1' ?
                    <Button variant='' className='mark_as_closed' onClick={markAsSoldOrClosed}>
                        <small>
                            {i18n.t('mark_as_sold_closed')}
                        </small>
                    </Button>
                : publication.publication_status === '2' ?
                    <small className='publication_status'>
                        {i18n.t('publication_closed')}
                    </small>
                : publication.publication_status === '3' ?
                    <small className='publication_status'>
                        {i18n.t('publication_bloqued')}
                    </small>
                : null
            :
                <Button variant='' className='report_publication' onClick={openReportModal}>
                    <small>{i18n.t('report_publication')}</small>
                </Button>
            }
            <ReportModal showReportModal={showReportModal} closeReportModal={closeReportModal} publicationId={publication.id} language={language} />
        </div>
    );
}

export default CloseOrReportPublication;
