import i18n from 'i18n-js';

import fr from '../translations/fr.json';
import ar from '../translations/ar.json';
import en from '../translations/en.json';

const fallback = {languageTag: 'fr', isRTL: false};

i18n.fallbacks = true;
i18n.defaultLocale = 'fr';
i18n.translations = { fr, ar, en };

export default i18n;
