import React, { useState } from 'react';
import { Button, Modal, Form, Container } from 'react-bootstrap';
import {reportPublication} from '../../../API/publication';
import i18n from '../../../Utils/i18n';
import DisplayLoading from '../../Partials/DisplayLoading';
import { useDispatch } from 'react-redux';
import { validateEmail } from '../../../Utils/functions';

const ReportModal = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    
    const publication_id = props.publicationId;
    const language = props.language;

    const _reportPublication = () => {
        if(name) {
            if(email) {
                if(validateEmail(email)) {
                    if(reason) {
                        if(message) {
                            setIsLoading(true);
                            reportPublication(name, email, reason, message, publication_id)
                            .then(res =>  {
                                if(res.Error) {
                                    showAlert('error', 'error');
                                } else {
                                    setToInitial();
                                    dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('report_send'), type: 'success'}});
                                    props.closeReportModal()
                                }
                            })
                            .catch(err => showAlert('error', 'error'));
                        } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: `${i18n.t('fill_input')} ${i18n.t('message')}`, type: 'info'}});
                    } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: `${i18n.t('fill_input')} ${i18n.t('reason')}`, type: 'info'}});
                } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('wrong_email_format'), type: 'info'}});
            } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: `${i18n.t('fill_input')} ${i18n.t('email')}`, type: 'info'}});
        } else dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: `${i18n.t('fill_input')} ${i18n.t('name')}`, type: 'info'}});
    }

    const showAlert = (message, type) => {
        setIsLoading(false);
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t(message), type}});
    }

    const handleChange = event => {
        let value = event.target.value;
        let id = event.target.id;

        switch (id) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'reason':
                setReason(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    }

    const setToInitial = () => {
        setName('');
        setEmail('');
        setReason('');
        setMessage('');
        setIsLoading(false);
    }

    return (
        <Modal
            show={props.showReportModal} onHide={() => {setToInitial(); props.closeReportModal()}}
            width='15px'
            aria-labelledby='contained-modal-title-vcenter'
            centered >
            <Modal.Body className='user_action_modal'>
                <h3 className='title'>
                    {i18n.t('publication_reporting')}
                </h3>
                <Container className='body' dir={language === 'ar' ? 'rtl' : 'ltr'}>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                type='text'
                                placeholder={i18n.t('full_name')}
                                id='name'
                                value={name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type='text'
                                placeholder={i18n.t('email')}
                                id='email'
                                value={email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type='text'
                                placeholder={i18n.t('reason')}
                                id='reason'
                                value={reason}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                as='textarea'
                                rows='3'
                                placeholder={i18n.t('message')}
                                id='message'
                                value={message}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>

                    <Button
                        variant='flat'
                        onClick={_reportPublication} >
                        {isLoading ? <DisplayLoading /> : i18n.t('send')}
                    </Button>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ReportModal;
