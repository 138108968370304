const initialState = {userData: {}, userProfil: {}};

function getUserData(state = initialState, action ) {
    let nextState = {};
    switch (action.type) {
        case 'UPDATE_USER_DATA':
            nextState.userData = action.data;
            return {...state, ...nextState};
        case 'REMOVE_USER_DATA':
            state = initialState;
            return state;
        case 'UPDATE_USER_PROFIL':
            nextState.userProfil = action.data;
            return {...state, ...nextState};
        case 'UPDATE_USER_PROFIL_IMG':
            state.userProfil.img_url = action.data.img_url;
            nextState = {...state, ...state.userProfil};
            return nextState;
        default:
            return state;
    }
}

export default getUserData;
