import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {publicationById, suggestions, addNewView} from '../../API/publication';
import i18n from '../../Utils/i18n';

import FacebookLogo from '../../assets/facebook.svg';
import InstagramLogo from '../../assets/instagram.svg';
import TwitterLogo from '../../assets/twitter.svg';
import WhatsappLogo from '../../assets/whatsapp.svg';

import {FacebookShareButton, TwitterShareButton, WhatsappShareButton,} from 'react-share';
import PublicationCard from './PublicationCard';
import ProfilPanel from '../User/ProfilPanel';

import { useDispatch, useSelector } from 'react-redux';
import ImagesContainer from './Details/ImagesContainer';
import DetailsContainer from './Details/DetailsContainer';
import CloseOrReportPublication from './Details/CloseOrReportPublication';

const Publication = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userData = useSelector(state => state.userData.userData);
    const pViews = useSelector(state => state.pViews.pViews);
    const language = useSelector(state => state.language.language);
    const [shareUrl, setShareUrl] = useState('');
    const [publicationData, setPublicationData] = useState({});
    const [publications, setPublications] = useState(null);
    const [images, setImages] = useState(null);
    const [alreadyViewed, setAlreadyViewed] = useState(true);

    const publication = location.state.data;
    const {token, refresh_token, id} = userData;
    const {img_url, telephone, telephone_status, username, rating, }  = publicationData;

    const data = {
        user_id: publication?.user_id, img_url, telephone, telephone_status,
        username, rating, token, refresh_token, connectedUserId: userData.id
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
        loadSuggestionsData();
        setAsViewed();
        setShareUrl(`https://aryilo.com/publications/publicaion?id=${publication.id}`)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
    }, [publication?.id]);

    const setAsViewed = () => {
        /** if there are views array  */
        if(pViews && pViews.length > 0) {
            /** if the user have'nt seen the publication  */
            if(!pViews.includes(publication.id)) {
                dispatch({type: 'MARK_AS_VIEWED', data: publication.id});
                addNewView(publication.id);
                setAlreadyViewed(false);
            }
        } else {
            dispatch({type: 'MARK_AS_VIEWED', data: publication.id});
            addNewView(publication.id);
            setAlreadyViewed(false);
        }
    };

    const loadData = () => {
        publicationById(publication.user_id, userData?.id , publication.id)
        .then(res =>  {
            if(res.Error) {
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            } else {
                setPublicationData({
                    ...res.profil[0], ...res.likes[0], ...res.user[0],
                    ...res.rating[0], fav: res.fav[0].fav !== 0 ? true : false
                });
                setImages(res.images);
            }
        })
        .catch(err => dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}}));
    }

    const loadSuggestionsData = () => {
        suggestions(publication.category_name, publication.id)
        .then(res =>  {
            if(res.Error) {
                setPublications([]);
                dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
            } else {
                setPublications(res.publication);
            }
        })
        .catch(err => {
            setPublications([]);
            dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('error'), type: 'error'}});
        });
    }

    const setLikesChange = (fav, likes) => setPublicationData({...publicationData, fav, likes});

    const setPublicationStatusChange = (publication_status) => {
        if(location.state?.data)
            location.state.data.publication_status = publication_status;
        //setPublicationData({...publications, publication_status});
    }

    return (
        <Container className='Body-container'>
            <ProfilPanel props={props} data={data} />

            <Container className='publication'>
                <h3 className='title'>
                    {publication.product_service_name}
                </h3>
                <ImagesContainer images={images} />

                <h3 className='title'>
                    {i18n.t('details')}
                </h3>
                <DetailsContainer publication={publication} data={data} _data={publicationData} setLikesChange={setLikesChange} alreadyViewed={alreadyViewed} language={language} />

                <div className='share_container'>
                    <p className='title'>{i18n.t('share_publication')}</p>
                    <FacebookShareButton url={shareUrl} className='shre_btn'>
                        <img src={FacebookLogo} alt='facebook logo' />
                    </FacebookShareButton> 
                    <TwitterShareButton url={shareUrl} className='shre_btn'>
                        <img src={TwitterLogo} alt='twitter logo' />
                    </TwitterShareButton>
                    <WhatsappShareButton url={shareUrl} className='shre_btn'>
                        <img src={WhatsappLogo} alt='whatsapp logo' />
                    </WhatsappShareButton>
                </div>
                <hr />

                <CloseOrReportPublication publication={publication} props={props} data={data} setPublicationStatusChange={setPublicationStatusChange} language={language} />
            </Container>

            {publications &&
            <>
                <h3 className='title'>
                    {i18n.t('others_publications_like')} {publication.product_service_name}
                </h3>

                <Row className='publication_row'>
                    {publications.map((publication, index) =>
                        <PublicationCard props={props} publication={publication} key={index} />
                    )}
                </Row>
            </>
            }

        </Container>
    );
}

export default Publication;

