import React, { useState } from 'react';
import ViewIcon from '../../assets/view.svg';
import { Figure, Col } from 'react-bootstrap';
import {cities, countries} from '../../Utils/functions';
import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr';
import ar from 'javascript-time-ago/locale/ar';
import en from 'javascript-time-ago/locale/en';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import i18n from '../../Utils/i18n';
import DisplayLoading from '../Partials/DisplayLoading';

let img = (img) => {return process.env.REACT_APP_API_PUBLICATION_IMG_URL + img.url};

const PublicationCard = (props) => {
    const language = useSelector(state => state.language.language);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const publication = props.publication;

    let timeAgo;
    if(language) {
        if(language === 'ar') {
            TimeAgo.addLocale(ar);
            timeAgo = new TimeAgo('ar');
        } else if(language === 'fr'){
            TimeAgo.addLocale(fr);
            timeAgo = new TimeAgo('fr');
        } else if(language === 'en'){
            TimeAgo.addLocale(en);
            timeAgo = new TimeAgo('en');
        }
    } else {
        TimeAgo.addLocale(fr);
        timeAgo = new TimeAgo('fr');
    }
    
    let mcity;
    let country_city;

    return (
        <Figure className="publication_container zoom" 
            onClick={() => history.push({pathname: '/publications/publication', search: '?id='+publication.id,  state:{id: '0', data: publication}})} >
            {isLoading && <div className='img_spinner'><DisplayLoading/></div>}
            <Figure.Image
                className="img"
                onLoad={() => setIsLoading(false)}
                alt="Image"
                style={{marginTop: 2, borderRadius: 10}}
                src={publication.url ? img(publication) : require('../../assets/publication_image.png')}
            />

            <p className="views">
                <span>
                    <small className='label'>{publication.views}</small>
                    <img src={ViewIcon} alt="Twitter Logo" className='icon' />
                </span>
            </p>
            <p className="publication_date">
                <span>
                    <small>{timeAgo.format(parseInt(publication.publication_creation_date))}</small>
                </span> 
            </p>
            <Figure.Caption>
                <small className='label'>{publication.product_service_name.substring(0, 29)}</small>
                <Col>
                    <b dir='ltr'>{publication.price} MRU</b>
                </Col>
                <small>
                    {(cities.flatMap((city) => { if(city.id === publication.city) { mcity = city.name } } ),
                        countries.flatMap((country) => { if(country.id === publication.country) { country_city = `${i18n.t(mcity)}, ${i18n.t(country.name)}`} } )
                    )}
                    {country_city.substring(0, 29)}
                </small>
            </Figure.Caption>
        </Figure>
    );
}

export default PublicationCard;
