const initialState = {token: '', platform: '', is_user_registered: false, id: ''};

const notificationToken = (state = initialState, action ) => {
    let nextState = new Object();
    switch (action.type) {
        case 'SAVE_NOTIFICATION_TOKEN':
            nextState.token = action.data.token;
            nextState.platform = action.data.platform;
            nextState.is_user_registered = action.data.is_user_registered;
            nextState.id = action.data.id;
            return nextState;
        case 'REMOVE_NOTIFICATION_TOKEN':
            nextState = initialState;
            return nextState;
        default:
            return state;
    }
}

export default notificationToken;
