import React, { useEffect, useState, useCallback } from 'react';
import { Button, Image, Form, Container } from 'react-bootstrap';
import i18n from '../../Utils/i18n';
import {upload, changeStatus} from '../../API/publication';
import axios from 'axios';
import {categories, product_status, cities, countries, getDecodedToken} from '../../Utils/functions';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadIcon from '../../assets/upload.svg';
import DisplayLoading from '../Partials/DisplayLoading';
import {useDispatch, useSelector} from 'react-redux';

const Upload = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData.userData);
    const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);

    const [category, setCategory] = useState();
    const [productServiceName, setProductServiceName] = useState();
    const [details, setDetails] = useState();
    const [price, setPrice] = useState();
    const [productStatus, setProductStatus] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [images, setImages] = useState([]);

    const [data, setData] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isLoading, setIsLoading] = useState(false);
    let publication_id;


    const handleChange = event => {
        let value = event.target.value;
        let id = event.target.id;

        switch (id) {
            case 'category':
                setCategory(value);
                break;
            case 'product_service_name':
                setProductServiceName(value);
                break;
            case 'details':
                setDetails(value);
                break;
            case 'price':
                setPrice(value);
                break;
            case 'product_status':
                setProductStatus(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'country':
                setCountry(value);
                break;
            default:
                break;
        }
    }

    const onChangeHandler = (event) => {
        let _files = event.target.files;

        if(_files.length > 1) {
            for(let i = 0; i < _files.length; i++) {
                setImages([...images, _files[i]]);
                setData([...data, URL.createObjectURL(_files[i])]);
            }
        } else if(_files.length === 1) {
            setImages([...images, _files[0]]);
            setData([...data, URL.createObjectURL(_files[0])]);
        }
    }

    const removeImage = (imageIndex) => {
        const _images = images.filter((e, i) => i !== imageIndex);
        const _data = data.filter((e, i) => i !== imageIndex);

        setImages(_images);
        setData(_data);
        forceUpdate();
    }

    useEffect(() => {
        if(isTokenRefreshed) {
            uploadPublication();
            setIsTokenRefreshed(false);
        }
    }, [isTokenRefreshed]);

    const uploadPublication = () => {
        const publication_creation_date = +new Date();
        const {id, token, refresh_token} = userData;
        const publication = {category, productServiceName, details, price, productStatus, address, city, country, images, user_id: id};

        if(category) {
            if(productServiceName) {
                if(details) {
                    if(price) {
                        if(productStatus) {
                            if(address) {
                                if(city) {
                                    if(country) {
                                        if(images.length > 0) {
                                            setIsLoading(true);
                                            const _data = new FormData();
                                            const urls = [];

                                            for(let i = 0; i< images.length; i++) {
                                                _data.append('file', images[i], publication_creation_date+'-'+id+'-'+i+'.png');
                                                urls.push(publication_creation_date+'-'+id+'-'+i+'.png');
                                            }
                                            upload(publication, urls, publication_creation_date, token, refresh_token)
                                            .then((res) => {
                                                if(res.Error) {
                                                    showAlert('error', 'error');
                                                } else if(res.Message === 'Token refreshed') {
                                                    const payload = getDecodedToken(res);
                                                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                                                    setIsTokenRefreshed(true);
                                                } else {
                                                    publication_id = res.publication_id;
                                                    axios.post(process.env.REACT_APP_API_URL + 'img/upload/publication', _data, { })
                                                    .then((res) => {
                                                        if(res.data.Error) {
                                                            showAlert('error', 'error');
                                                        } else if(res.Message === 'Token refreshed') {
                                                            const payload = getDecodedToken(res);
                                                            const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                                                            setIsTokenRefreshed(true);
                                                        } else {
                                                            changeStatus('1', id, publication_id, token, refresh_token)
                                                            .then((res) => {
                                                                if(res.Error) {
                                                                    showAlert('error', 'error');
                                                                } else if(res.Message === 'Token refreshed') {
                                                                    const payload = getDecodedToken(res);
                                                                    const dispatchreturn = dispatch({type: 'UPDATE_USER_DATA', data: payload});
                                                                    setIsTokenRefreshed(true);
                                                                } else {
                                                                    wipeForm();
                                                                }
                                                            })
                                                            .catch(err => showAlert('error', 'error'));
                                                        }
                                                    })
                                                }
                                            })
                                            .catch((err) => showAlert('error', 'error') );
                                        } else showErrorMessage(i18n.t('add_image'));
                                    } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('country')}`);
                                } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('city')}`);
                            } showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('address')}`);
                        } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('product_status')}`);
                    } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('price')}`);
                } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('details')}`);
            } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('product_service_name')}`);
        } else showErrorMessage(`${i18n.t('fill_input')} ${i18n.t('category')}`);
    }

    const wipeForm = () => {
        setCategory(0);
        setProductServiceName('');
        setDetails('');
        setPrice('');
        setProductStatus('');
        setAddress('');
        setCity('');
        setCountry('');
        setImages([]);
        setData([]);
        setIsLoading(false);
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t('publication_published'), type: 'success'}});
        forceUpdate();
    }

    const showErrorMessage = (message) => {
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message, type: 'info'}});
    }

    const showAlert = (message, type) => {
        setIsLoading(false);
        dispatch({type: 'SEND_ALERT_MESSAGE', data: {title: i18n.t('publication'), message: i18n.t(message), type}});
    }

    return (
        <Container className="Body-container">
            <h3 className='title'>
                {i18n.t('add_publication')}
            </h3>
            {!userData.id ?
                <div className='not_connected_card'>
                    <div className='_card'>
                        <img src={require('../../assets/logo.png')} alt='app-logo' className='img' />
                        <p className='label'>{i18n.t('youre_not_connected')}</p>
                        <Button
                            variant='flat'
                            onClick={props.openModal.bind(this, 'signin')} >
                            <small>{i18n.t('signin')}</small>
                        </Button>
                    </div>
                </div>
            :  
            <Container className='form_container'>
                <Form className='form'>
                    <Form.Group>
                        <Form.Control
                            as='select'
                            id='category'
                            value={category}
                            onChange={handleChange}
                            className='input_style'>
                            <option value={null} >{i18n.t('category')}</option>
                            {categories.flatMap((category) => { if(category.id !== '0') { return (<option value={category.id}>{i18n.t(category.name)}</option>)} } )}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder={i18n.t('product_service_name')}
                            id='product_service_name'
                            value={productServiceName}
                            onChange={handleChange}
                            className='input_style'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            placeholder={i18n.t('details')}
                            id='details'
                            value={details}
                            onChange={handleChange}
                            className='input_style'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            type="number"
                            placeholder={i18n.t('price')}
                            id='price'
                            value={price}
                            onChange={handleChange}
                            className='input_style'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            as="select"
                            id='product_status'
                            value={productStatus}
                            onChange={handleChange}
                            className='input_style' >
                            <option value={null}>{i18n.t('product_status')}</option>
                            {product_status.map((status) => { return (<option value={status.id}>{i18n.t(status.name)}</option>) } )}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder={i18n.t('address')}
                            id='address'
                            value={address}
                            onChange={handleChange}
                            className='input_style'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            as="select"
                            id='city'
                            value={city}
                            onChange={handleChange}
                            className='input_style' >
                            <option value={null}>{i18n.t('city')}</option>
                            {cities.map((city) => { return (<option value={city.id}>{i18n.t(city.name)}</option>) } )}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            as="select"
                            id='country'
                            value={country}
                            onChange={handleChange}
                            className='input_style' >
                            <option value={null}>{i18n.t('country')}</option>
                            {countries.map((country) => { return (<option value={country.id}>{i18n.t(country.name)}</option>) } )}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.File 
                            id="custom-file"
                            name="file"
                            multiple
                            onChange={onChangeHandler}
                            data-browse={i18n.t('browse')}
                            label={
                                <div>
                                    <img src={UploadIcon} className="upload icon" style={{margin: 5}} /> <br/>
                                    <small>{i18n.t('drop_your_images_here')}</small>
                                </div>
                            }
                            height='auto'
                            custom={true}
                            as='div'
                        />
                    </Form.Group>

                    <div className='img_preview_container'>
                        {images ? ( data.map((image, index) => {return(
                            <>
                                <Image src={image} className='img_preview' alt='img-preview' />
                                <FontAwesomeIcon className='remove_img_icon' icon={faWindowClose} color='#34ebc0' onClick={() => removeImage(index)} />
                            </>
                        ) }) ) : null}
                    </div>

                    <Button
                        variant='flat'
                        onClick={uploadPublication} >
                        {isLoading ? <DisplayLoading /> : i18n.t('publish')}
                    </Button>
                </Form>
            </Container>
            }
        </Container>
    );
}

export default Upload;
